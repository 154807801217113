import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';

import {
  apiRequest,
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOCAL_STORAGE_REFRESH_TOKEN_KEY,
} from '../helpers/api';

export interface User {
  _id: string;
  firstName?: string;
  lastName?: string;
  profilePictureUrl?: string;
  email: string;
  locale: string;
}

interface Tokens {
  accessToken: string;
  refreshToken?: string;
}

interface AuthState {
  user: User | null;
  isLogged: boolean;
  isLoading: boolean;
  isInitialLoading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  isLogged: false,
  isLoading: false,
  isInitialLoading: true,
  user: null,
  error: null,
};

const setAccessRefreshTokenAndGetUser = (
  accessToken: string,
  refreshToken?: string,
) => {
  localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken);
  if (refreshToken) {
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken);
  }

  return apiRequest<User>('GET', '/auth/user');
};

/* Thunks */

export const fetchCurrentUser = createAsyncThunk(
  'auth/fetchCurrentUser',
  async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);

    if (!accessToken) {
      return null;
    }

    try {
      return await apiRequest<User>('GET', '/auth/user');
    } catch (error) {
      return null;
    }
  },
);

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (payload: { email: string; password: string }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'PUT',
      '/auth/signIn',
      undefined,
      payload,
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (payload: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'POST',
      '/auth/signUp',
      undefined,
      payload,
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const signInUpGoogle = createAsyncThunk(
  'auth/signInUpGoogle',
  async (payload: { googleAccessToken: string }) => {
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'POST',
      '/auth/google',
      undefined,
      // eslint-disable-next-line camelcase
      { access_token: payload.googleAccessToken },
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

export const sendLostPassword = createAsyncThunk(
  'auth/sendLostPassword',
  async (payload: { email: string }) => {
    await apiRequest<{ status: boolean }>(
      'POST',
      '/auth/lostPassword',
      undefined,
      payload,
    );
  },
);

export const useLostPasswordToken = createAsyncThunk(
  'auth/useLostPasswordToken',
  async (payload: { token: string; password: string }) => {
    const { token, password } = payload;
    const { accessToken, refreshToken } = await apiRequest<Tokens>(
      'PUT',
      `/auth/lostPassword/${encodeURIComponent(token)}`,
      undefined,
      { password },
    );

    return await setAccessRefreshTokenAndGetUser(accessToken, refreshToken);
  },
);

/* Shared reducers */
const signInUpPendingReducer = (state: AuthState) => {
  state.isLogged = false;
  state.isLoading = true;
  state.error = null;
  state.user = null;
};

const signInUpFulfilledReducer = (
  state: AuthState,
  { payload }: { payload: User },
) => {
  state.isLogged = true;
  state.isLoading = false;
  state.user = payload;
};

const pendingReducer = (state: AuthState) => {
  state.isLoading = true;
  state.error = null;
};

const fulfilledReducer = (state: AuthState) => {
  state.isLoading = false;
};

const rejectedReducer = (
  state: AuthState,
  { error }: { error: SerializedError },
) => {
  state.isLoading = false;
  state.error = error.message || 'error';
};

/* Slice */

const authSlice = createSlice({
  name: 'auth',
  reducers: {
    logout(state: AuthState) {
      state.user = null;
      state.isLogged = false;

      localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
      localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state: AuthState) => {
        state.isInitialLoading = true;
      })
      .addCase(fetchCurrentUser.fulfilled, (state: AuthState, { payload }) => {
        state.isInitialLoading = false;
        state.user = payload;
        state.isLogged = !!payload;
      })
      .addCase(signIn.pending, signInUpPendingReducer)
      .addCase(signIn.fulfilled, signInUpFulfilledReducer)
      .addCase(signIn.rejected, rejectedReducer)
      .addCase(signUp.pending, signInUpPendingReducer)
      .addCase(signUp.fulfilled, signInUpFulfilledReducer)
      .addCase(signUp.rejected, rejectedReducer)
      .addCase(signInUpGoogle.pending, signInUpPendingReducer)
      .addCase(signInUpGoogle.fulfilled, signInUpFulfilledReducer)
      .addCase(signInUpGoogle.rejected, rejectedReducer)
      .addCase(sendLostPassword.pending, pendingReducer)
      .addCase(sendLostPassword.fulfilled, fulfilledReducer)
      .addCase(sendLostPassword.rejected, rejectedReducer)
      .addCase(useLostPasswordToken.pending, pendingReducer)
      .addCase(useLostPasswordToken.fulfilled, signInUpFulfilledReducer)
      .addCase(useLostPasswordToken.rejected, rejectedReducer);
  },
  initialState,
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;

import React, { Dispatch, SetStateAction, useState } from 'react';
import { useStyles } from '../../constants/theme/form';
import { Explanations } from './Explanations';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { Form, FormInstance } from 'antd';
import { Typography } from '@mui/material';

interface Props {
  form: FormInstance;
  display: string;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
  colors: any;
}

export const ColorPicker: React.VFC<Props> = ({
  form,
  display,
  openInfos,
  setOpenInfos,
  colors,
}) => {
  const classes = useStyles();
  const [currentPrimaryColor, setCurrentPrimaryColor] = useState(
    colors?.primary ? colors.primary : '#FFFFFF',
  );
  const [currentSecondaryColor, setCurrentSecondaryColor] = useState(
    colors?.secondary ? colors.secondary : '#FFFFFF',
  );

  const handlePrimaryColorChange: any = (color: any) => {
    setCurrentPrimaryColor(color);
    form.setFieldsValue({
      colors: { primary: '' + color },
    });

    return currentPrimaryColor;
  };

  const handleSecondaryColorChange: any = (color: any) => {
    setCurrentSecondaryColor(color);
    form.setFieldsValue({
      colors: { secondary: color },
    });
    return currentSecondaryColor;
  };
  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>Couleurs</Typography>
        <Form.Item label="Couleur primaire" name={['colors', 'primary']} />
        <HexColorPicker
          color={currentPrimaryColor}
          onChange={handlePrimaryColorChange}
        />
        <HexColorInput
          color={currentPrimaryColor}
          onChange={handlePrimaryColorChange}
          className={classes.colorPickerInput}
        />
        <div style={{ marginBottom: 5 }} />
        <Form.Item label="Couleur secondaire" name={['colors', 'secondary']} />
        <HexColorPicker
          color={currentSecondaryColor}
          onChange={handleSecondaryColorChange}
        />
        <HexColorInput
          color={currentSecondaryColor}
          onChange={handleSecondaryColorChange}
          className={classes.colorPickerInput}
        />
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section vous permet de
              <b> sélectionner la couleur principale du site</b>.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Elle sera automatiquement appliquée à tous les fonds colorés et
              aux différents boutons.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Vous pouvez la changer à tout moment.
            </Typography>
          </div>
        }
      />
    </div>
  );
};

import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './hooks/store';
import { fetchCurrentUser } from './slices/auth';
import { GuestSwitch } from './routes/GuestSwitch';
import { LoggedSwitch } from './routes/LoggedSwitch';
import { FullscreenSpin } from './components/basic/FullscreenSpin';
import './locales/i18n';
import ThemeConfig from './constants/theme';
import GlobalStyles from './constants/theme/globalStyles';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { StyledEngineProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';

const App: React.VFC = () => {
  const dispatch = useAppDispatch();
  const { isLogged, isInitialLoading } = useAppSelector(
    ({ auth: { isLogged, isInitialLoading } }) => ({
      isLogged,
      isInitialLoading,
    }),
  );

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeConfig>
        <GlobalStyles />
        <BaseOptionChartStyle />
        <I18nextProvider i18n={i18next}>
          {isInitialLoading ? (
            <FullscreenSpin />
          ) : (
            <SnackbarProvider>
              <Router>{isLogged ? <LoggedSwitch /> : <GuestSwitch />}</Router>
            </SnackbarProvider>
          )}
        </I18nextProvider>
      </ThemeConfig>
    </StyledEngineProvider>
  );
};

export default App;

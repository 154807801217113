import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core';
import React from 'react';

interface Props {
  open: boolean;
  onClickYes?: () => void;
  requestClose: () => void;
  title: string;
}

export const YesNoPopup: React.VFC<Props> = ({
  open,
  onClickYes,
  requestClose,
  title,
}) => {
  return (
    <Dialog
      open={open}
      onClose={requestClose}
      sx={{
        '& .MuiTypography-root': {
          marginTop: '20px',
          marginBottom: '100px',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <Box position="absolute" top={100} right={130}>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (onClickYes) {
                onClickYes();
              }
              requestClose();
            }}
          >
            Oui
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

import { Form as FormAntd } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { Contact } from 'src/components/form/Contact';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  getProject,
  updateDraftProject,
  updateFormSaving,
  updateIsProjectPublished,
} from 'src/slices/project';
import { debounce } from 'lodash';
import { CustomDomain } from 'src/containers/editProject/CustomDomain';
import { CustomerBenefits } from 'src/components/form/CustomerBenefits';
import { Faq } from 'src/components/form/Faq';
import { Home } from 'src/components/form/Home';
import { Pricing } from 'src/components/form/Pricing';
import { References } from 'src/components/form/References';
import { Solution } from 'src/components/form/Solution';
import { Team } from 'src/components/form/Team';
import { Testimonials } from 'src/components/form/Testimonials';
import { Footer } from '../components/form/Footer';
import { ColorPicker } from '../components/form/ColorPicker';
import { GoogleAnalytics } from '../components/form/GoogleAnalytics';
import { SpecialOffer } from 'src/components/form/SpecialOffer';
import { Login } from 'src/components/form/Login';
import { AdditionalPages } from 'src/components/form/AdditionalPages';
import { FullscreenSpin } from '../components/basic/FullscreenSpin';
import { EditProjectHeader } from 'src/containers/editProject/EditProjectHeader';
import { EditProjectSider } from 'src/containers/editProject/EditProjectSider';

const useStyles = createUseStyles({
  container: {
    '& .ant-form-item': {
      padding: '5px 0',
    },
  },
  main: {
    display: 'flex',
    background: 'white',
    '@media(min-width: 751px)': {
      height: 'calc(100vh - 108px)',
    },
    '@media(max-width: 750px)': {
      minHeight: '90vh',
    },
  },
  form: {
    '@media(min-width: 751px)': {
      height: 'calc(100vh - 158px)',
      paddingBottom: 50,
    },
    width: '79%',
    paddingRight: 20,
    marginTop: 34,
    '& .ant-form-item': {
      border: 'none',
      boxSizing: 'border-box',
      borderRadius: '7px',
      '& .ant-input': {
        border: 'none',
        backgroundColor: '#f5f5f5',
        padding: '10px 20px',
        borderRadius: 10,
      },
    },
    '@media(max-width: 750px)': {
      width: '100%',
      paddingLeft: '5vw',
      paddingRight: '5vw',
    },
  },
});

const layout = {
  labelCol: { span: 24 },
  requiredMark: false,
  colon: false,
};

export const Form: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  // TODO : USE FORMIK FORM NOT ANTD
  const [form] = FormAntd.useForm();
  const { slug } = useParams<{ slug: string }>();
  const [currentFormItem, setCurrentForm] = useState<string>('home');
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openInfos, setOpenInfos] = useState<boolean>(false);
  const [isProjectLoaded, setIsProjectLoaded] = useState<boolean>(false);
  const { project, formSaving } = useAppSelector(
    ({ project: { project, formSaving } }) => ({
      project,
      formSaving,
    }),
  );

  const saveDraftDebounced = debounce(async (values) => {
    await dispatch(
      updateDraftProject({
        data: values,
        slug: slug,
      }),
    );
    dispatch(updateFormSaving('saved'));
    setTimeout(() => dispatch(updateFormSaving('notSaving')), 3000);
  }, 2000);

  const setToSavingDebounced = debounce(async () => {
    formSaving !== 'saving' && dispatch(updateFormSaving('saving'));
  }, 800);

  const getProjectData = async () => {
    await dispatch(getProject(slug));
    form.resetFields();
    setIsProjectLoaded(true);
  };

  useEffect(() => {
    getProjectData();
  }, []);

  useEffect(() => {
    if (project && project.draft) {
      form.setFieldsValue({ ...project.draft });
    }
  }, []);

  if (!project || !project.draft) {
    if (!isProjectLoaded) {
      return <FullscreenSpin />;
    }
  }

  return (
    <div className={classes.container}>
      <EditProjectHeader
        formValidation={form}
        projectValidation={project}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        setOpenInfos={setOpenInfos}
        openInfos={openInfos}
      />
      <div className={classes.main}>
        <EditProjectSider
          currentFormItem={currentFormItem}
          setCurrentForm={setCurrentForm}
          setOpenDrawer={setOpenDrawer}
          openDrawer={openDrawer}
        />
        <div className={classes.form}>
          <FormAntd
            {...layout}
            form={form}
            preserve={true}
            onValuesChange={(_, values) => {
              saveDraftDebounced(values);
              dispatch(updateIsProjectPublished(false));
              setToSavingDebounced();
            }}
            initialValues={project?.draft}
          >
            <Home
              display={currentFormItem === 'home' ? '' : 'none'}
              form={form}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
              saveDraftDebounced={saveDraftDebounced}
            />
            <Solution
              display={currentFormItem === 'solution' ? '' : 'none'}
              form={form}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
              saveDraftDebounced={saveDraftDebounced}
            />
            <CustomerBenefits
              display={currentFormItem === 'benefits' ? '' : 'none'}
              form={form}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
              saveDraftDebounced={saveDraftDebounced}
            />
            <Contact
              display={currentFormItem === 'contact' ? '' : 'none'}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
            />
            <Team
              display={currentFormItem === 'team' ? '' : 'none'}
              form={form}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
              saveDraftDebounced={saveDraftDebounced}
            />
            <Testimonials
              display={currentFormItem === 'testimonials' ? '' : 'none'}
              form={form}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
              saveDraftDebounced={saveDraftDebounced}
            />
            <References
              display={currentFormItem === 'references' ? '' : 'none'}
              form={form}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
              saveDraftDebounced={saveDraftDebounced}
            />
            <Faq
              display={currentFormItem === 'faq' ? '' : 'none'}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
            />
            <Pricing
              display={currentFormItem === 'pricing' ? '' : 'none'}
              form={form}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
            />
            <Footer
              display={currentFormItem === 'footer' ? '' : 'none'}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
            />
            {currentFormItem === 'customDomain' && (
              <CustomDomain setOpenInfos={setOpenInfos} openInfos={openInfos} />
            )}
            <SpecialOffer
              display={currentFormItem === 'specialoffer' ? '' : 'none'}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
            />
            <Login
              display={currentFormItem === 'login' ? '' : 'none'}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
            />
            <ColorPicker
              form={form}
              display={currentFormItem === 'colorpicker' ? '' : 'none'}
              setOpenInfos={setOpenInfos}
              colors={
                project?.published?.colors ? project.published.colors : {}
              }
              openInfos={openInfos}
            />
            <AdditionalPages
              display={currentFormItem === 'pages' ? '' : 'none'}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
            />
            <GoogleAnalytics
              display={currentFormItem === 'googleAnalytics' ? '' : 'none'}
              setOpenInfos={setOpenInfos}
              openInfos={openInfos}
            />
          </FormAntd>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import {
  ListItemButton,
  Typography,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

// ==============================|| CUSTOM MAIN ListItem ||============================== //

interface Props {
  title: string;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
  selected: boolean;
  icon?: any;
}

const ListItemCustom: React.VFC<Props> = ({
  title,
  selected,
  onClick,
  icon,
}) => {
  return (
    <ListItemButton
      onClick={onClick}
      selected={selected}
      sx={{
        borderRadius: `10px`,
        alignItems: 'flex-start',
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={<Typography>{title}</Typography>} />
    </ListItemButton>
  );
};

export default ListItemCustom;

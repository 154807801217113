import React from 'react';
import { Button, Divider, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { create } from 'src/slices/project';
import { useHistory } from 'react-router-dom';
import { ProjectSlug } from './ProjectSlug';
import { useStyles } from '../../constants/theme/form';

// const useStyles = createUseStyles({
//   container: {},
//   button: {
//     fontWeight: 600,
//     color: '#FFF',
//     backgroundColor: '#143779',
//     fontSize: '16px',
//     height: '45px',
//     borderRadius: '10px',
//     border: 'none',
//     padding: '20px',
//     '&:hover': {
//       cursor: 'pointer',
//       color: 'white',
//       backgroundColor: 'black',
//       borderColor: 'black',
//     },
//   },
//   submitButton: {
//     '&:hover': {
//       cursor: 'pointer',
//       color: 'white',
//       backgroundColor: 'black',
//       borderColor: 'black',
//     },
//   },
//   cancelButton: {},
// });

export const NewProjectButton: React.VFC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { isSlugValid } = useAppSelector(({ project: { isSlugValid } }) => ({
    isSlugValid,
  }));

  const SignInSchema = Yup.object().shape({
    name: Yup.string().required('Le nom est obligatoire'),
    slug: Yup.string().required('L&aposurl est obligatoire'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      slug: '',
    },
    validationSchema: SignInSchema,
    onSubmit: async (values) => {
      if (error) {
        setError(undefined);
      }
      if (!isSlugValid) return;
      dispatch(create({ ...values }));
      history.push(`/edit/${values.slug}`);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const { isSubmitting, handleSubmit } = formik;

  return (
    <div>
      <Button
        className={classes.newProjectButton}
        onClick={() => setOpen(true)}
      >
        Nouveau projet
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialogContent-root': {
            paddingTop: '30px',
          },
          '& .MuiDialogContentText-root': {
            marginTop: '20px',
            marginBottom: '30px',
          },
        }}
      >
        <DialogTitle>Nouveau Projet</DialogTitle>
        <Divider />
        <ProjectSlug formik={formik} newProject={true} />
        <DialogActions>
          <Stack direction="row">
            <Button onClick={() => setOpen(false)}>Annuler</Button>
            <LoadingButton
              className={classes.submitButton}
              variant="contained"
              loading={isSubmitting}
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
              disabled={!isSlugValid}
            >
              Valider
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};

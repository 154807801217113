import React, { Dispatch, SetStateAction } from 'react';
import { Form, FormInstance } from 'antd';
import { useSnackbar } from 'notistack';
import { useFormRules } from '../../hooks/useFormRules';
import { defaultsUploadImageProps, Upload } from './Upload';
import { useStyles } from '../../constants/theme/form';
import { isImageFormatValid } from '../../utils/upload';
import { Explanations } from './Explanations';
import { Button, TextField, Typography } from '@mui/material';

interface Props {
  display: string;
  form: FormInstance;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
  saveDraftDebounced: (values: any) => void;
}

export const Solution: React.VFC<Props> = ({
  display,
  form,
  openInfos,
  setOpenInfos,
  saveDraftDebounced,
}) => {
  const rules = useFormRules();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onSuccessImageUpload = (info: any) => {
    if (isImageFormatValid(info.type)) {
      enqueueSnackbar('Chargement du fichier réussi', { variant: 'success' });
      const fields = form.getFieldsValue();
      const { solution } = fields;
      const updatedFields = {
        ...fields,
        solution: { ...solution, imageUrl: info.response },
      };

      form.setFieldsValue(updatedFields);
      saveDraftDebounced(updatedFields);
    } else {
      enqueueSnackbar('Seul les formats jpeg et png sont acceptés', {
        variant: 'error',
      });
    }
  };

  const uploadImageProps = {
    ...defaultsUploadImageProps,
    onSuccess: onSuccessImageUpload,
  };

  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>
          Présentation de la solution
        </Typography>
        <Form.Item
          label="Punchline"
          name={['solution', 'punchline']}
          rules={[rules.required()]}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
        <Form.Item
          label="Description de la solution"
          name={['solution', 'description']}
          rules={[rules.required()]}
        >
          <TextField size="small" multiline fullWidth />
        </Form.Item>
        <Form.Item
          label="Illustration de la solution"
          name={['solution', 'imageUrl']}
          rules={[rules.required()]}
          className={classes.formUpload}
        >
          <Form.Item
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.solution?.imageUrl !== nextValues.solution?.imageUrl
            }
            style={{ marginBottom: 0 }}
          >
            {({ getFieldValue }) => (
              <>
                <Upload {...uploadImageProps} />
                <img
                  src={getFieldValue(['solution', 'imageUrl'])}
                  style={{ height: 100, marginTop: 20 }}
                  alt="Solution"
                />
              </>
            )}
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Texte du bouton"
          name={['solution', 'callToAction', 'text']}
          rules={[rules.required()]}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
        <Form.Item
          label="Url du bouton"
          name={['solution', 'callToAction', 'url']}
          rules={[rules.required(), rules.url()]}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Utilisez cette section pour décrire
              <b> comment fonctionne votre service</b>.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              <b>
                L&apos;image doit suggérer les fonctionnalités que vous proposez
              </b>
              . Idéalement, utilisez une animation qui présente le parcours
              utilisateur.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              <b>
                Nous pouvons nous occuper pour vous de produire pour vous tous
                les visuels de votre site
              </b>
              , afin qu&apos;ils soient professionnels et harmonisés.
            </Typography>
            <Button variant="outlined" style={{ marginLeft: 0 }}>
              &rarr; Commander des visuels
            </Button>
          </div>
        }
      />
    </div>
  );
};

import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    height: 'calc(100vh - 142px)',
    '& .ant-form-item-label': {
      fontWeight: 'bold',
    },
    '@media(max-width: 750px)': {
      height: 'auto',
    },
  },
  formList: {
    '& .ant-space-item': {
      flexGrow: 1,
    },
    '& .ant-form-item': {
      border: 'none',
    },
  },
  formImagesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width: 750px)': {
      flexDirection: 'column',
    },
  },
  colorPickerInput: {
    display: 'block',
    boxSizing: 'border-box',
    width: '90px',
    margin: '20px 55px 0',
    padding: '6px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    background: '#eee',
    outline: 'none',
    font: 'inherit',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  colorPickerInputFocus: {
    borderColor: '#f8f8f8', // TODO : Make a variable to have the same color of the SiderList.tsx styles
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '65%',
    overflow: 'auto',
    paddingRight: 20,
    paddingBottom: 40,
    backgroundColor: 'white',
    '& .ant-form-item-label': {
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    '& .ant-form-item': {
      marginBottom: '40px',
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    '@media(max-width: 750px)': {
      width: '100%',
      overflow: 'visible',
      paddingRight: 0,
    },
  },
  formUpload: {
    paddingRight: '55px !important',
  },
  formTitle: {
    fontSize: '24px',
    lineHeight: '30px',
    marginTop: '1vw',
    marginBottom: '35px',
    marginLeft: 0,
    fontWeight: 'bolder',
  },
  formSubtitle: {
    fontSize: '20px',
    lineHeight: '30px',
    marginTop: 0,
    marginBottom: '35px',
    marginLeft: 0,
    fontWeight: 'bolder',
  },
  formSpace: {
    display: 'block',
    position: 'relative',
    border: '1px solid lightgrey',
    padding: 20,
    borderRadius: 20,
    marginBottom: 20,
    background: '#f0f8ff',
  },
  submitButton: {
    '&:hover': {
      cursor: 'pointer',
      color: 'white',
      backgroundColor: 'black',
      borderColor: 'black',
    },
  },
  newProjectButton: {
    fontWeight: 600,
    color: '#FFF',
    backgroundColor: '#143779',
    fontSize: '16px',
    height: '45px',
    borderRadius: '10px',
    border: 'none',
    padding: '20px',
    '&:hover': {
      cursor: 'pointer',
      color: 'white',
      backgroundColor: 'black',
      borderColor: 'black',
    },
  },
});

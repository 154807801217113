import React from 'react';
import { createUseStyles } from 'react-jss';
import { APP_NAME } from '../../constants/global';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  Button,
  ListItem,
  List,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { logout } from 'src/slices/auth';
import { useAppDispatch } from 'src/hooks/store';
import { NewProjectButton } from 'src/containers/dashboard/NewProjectButton';
import { Flex } from '../basic/Flex';

// ----------------------------------------------------------------------
const useStyles = createUseStyles({
  headerTitle: {
    fontWeight: 'bold',
  },
  button: {
    fontWeight: 600,
    color: '#FFF',
    backgroundColor: '#143779',
    fontSize: '16px',
    height: '45px',
    borderRadius: '10px',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
      color: 'white',
      backgroundColor: 'black',
      borderColor: 'black',
    },
  },
  leftButtonsContainerDesktop: {
    '@media(max-width: 750px)': {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'row',
  },
  leftButtonsContainerMobile: {
    '@media(min-width: 751px)': {
      display: 'none',
    },
  },
  menuIcon: {
    color: 'black',
  },
});

const Header = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10vh',
        marginTop: '1.2vh',
      }}
    >
      <Box>
        <h1 className={classes.headerTitle}> {APP_NAME} </h1>
      </Box>

      <Box className={classes.leftButtonsContainerDesktop}>
        <Box sx={{ p: 1 }}>
          <NewProjectButton />
        </Box>
        <Box>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle fontSize="large" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ p: 3 }}
            >
              <MenuItem onClick={() => dispatch(logout())}>
                {t('common.logout')}
              </MenuItem>
            </Menu>
          </div>
        </Box>
      </Box>
      <Box className={classes.leftButtonsContainerMobile}>
        <IconButton onClick={() => setOpenDrawer(true)}>
          <MenuIcon fontSize="large" className={classes.menuIcon} />
        </IconButton>
      </Box>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="top"
      >
        <Box role="presentation">
          <List>
            <ListItem
              style={{ justifyContent: 'center' }}
              onClick={() => dispatch(logout())}
            >
              <Button> {t('common.logout')}</Button>
            </ListItem>
            <ListItem style={{ justifyContent: 'center' }}>
              <NewProjectButton />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Flex>
  );
};

export default Header;

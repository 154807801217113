import React from 'react';
import { Button, Upload as UploadAntd, UploadProps } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from '../../helpers/api';
import { errorOnUpload } from '../../utils/upload';

const API_URL = process.env.REACT_APP_API_URL;

interface Props {
  name: string;
  endpoint: string;
  onSuccess?: (file: UploadFile) => void;
  onError?: (file: UploadFile) => void;
  uploadProps?: UploadProps;
  children?: React.ReactNode;
}
const uploadPropsFile: UploadProps = { showUploadList: false };

export const defaultsUploadImageProps = {
  name: 'file',
  endpoint: '/file/upload',
  onError: errorOnUpload,
  uploadProps: uploadPropsFile,
  text: 'Importer une image',
  children: <DownloadOutlined style={{ fontSize: 18, cursor: 'pointer' }} />,
};
/**
 * See ant design doc: https://ant.design/components/upload
 */
export const Upload: React.VFC<Props> = ({
  name,
  uploadProps,
  endpoint,
  onSuccess,
  onError,
  children,
}) => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);

  const onChange = (info: any) => {
    if (info.file.status === 'done') {
      if (onSuccess) {
        onSuccess(info.file);
      }
    } else if (info.file.status === 'error') {
      if (onError) {
        onError(info.file);
      }
    }
  };

  return (
    <UploadAntd
      {...uploadProps}
      name={name}
      onChange={onChange}
      data={{ nameTest: name }}
      action={API_URL + endpoint}
      headers={{
        authorization: `Bearer ${accessToken}`,
      }}
    >
      {children || <Button icon={<UploadOutlined />}>Click to Upload</Button>}
    </UploadAntd>
  );
};

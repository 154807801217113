import React, { Dispatch, SetStateAction } from 'react';
import { Checkbox, Form, FormInstance } from 'antd';
import { useFormRules } from '../../hooks/useFormRules';
import { defaultsUploadImageProps, Upload } from './Upload';
import { isFavicon, isImageFormatValid } from '../../utils/upload';
import { Explanations } from './Explanations';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useStyles } from '../../constants/theme/form';
import { useSnackbar } from 'notistack';

interface Props {
  display: string;
  form: FormInstance;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
  saveDraftDebounced: (values: any) => void;
}

export const Home: React.VFC<Props> = ({
  display,
  form,
  setOpenInfos,
  openInfos,
  saveDraftDebounced,
}) => {
  const rules = useFormRules();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onSuccessLogoUpload = (info: any) => {
    if (isImageFormatValid(info.type)) {
      enqueueSnackbar('Chargement du fichier réussi', { variant: 'success' });
      const fields = form.getFieldsValue();
      const { home } = fields;
      const updatedFields = {
        ...fields,
        home: { ...home, logoUrl: info.response },
      };

      form.setFieldsValue(updatedFields);
      saveDraftDebounced(updatedFields);
    } else {
      enqueueSnackbar('Seul les formats jpeg et png sont acceptés', {
        variant: 'error',
      });
    }
  };

  const onSuccessImageUpload = (info: any) => {
    if (isImageFormatValid(info.type)) {
      enqueueSnackbar('Chargement du fichier réussi', { variant: 'success' });
      const fields = form.getFieldsValue();
      const { home } = fields;
      const updatedFields = {
        ...fields,
        home: { ...home, imageUrl: info.response },
      };

      form.setFieldsValue(updatedFields);
      saveDraftDebounced(updatedFields);
    } else {
      enqueueSnackbar('Seul les formats jpeg et png sont acceptés', {
        variant: 'error',
      });
    }
  };

  const onSuccessIconUpload = (info: any) => {
    if (isFavicon(info.type)) {
      enqueueSnackbar('Chargement du fichier réussi', { variant: 'success' });
      const fields = form.getFieldsValue();
      const { home } = fields;
      const updatedFields = {
        ...fields,
        home: { ...home, faviconUrl: info.response },
      };

      form.setFieldsValue(updatedFields);
      saveDraftDebounced(updatedFields);
    } else {
      enqueueSnackbar('Seul le format png est accepté', {
        variant: 'error',
      });
    }
  };

  const uploadImageProps = {
    ...defaultsUploadImageProps,
  };
  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>
          Écran d&apos;accueil
        </Typography>
        <Form.Item
          label="Punchline"
          name={['home', 'punchline']}
          rules={[rules.required()]}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
        <div className={classes.formImagesContainer}>
          <Form.Item
            label="Logo"
            name={['home', 'logoUrl']}
            rules={[rules.required()]}
            className={classes.formUpload}
          >
            <Form.Item
              shouldUpdate={(prevValues, nextValues) =>
                prevValues.home?.logoUrl !== nextValues.home?.logoUrl
              }
            >
              {({ getFieldValue }) => (
                <>
                  <Upload
                    {...uploadImageProps}
                    onSuccess={onSuccessLogoUpload}
                  />
                  <img
                    src={getFieldValue(['home', 'logoUrl'])}
                    style={{ height: 100, marginLeft: 20 }}
                    alt="Logo"
                  />
                </>
              )}
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Image d'accueil"
            name={['home', 'imageUrl']}
            rules={[rules.required()]}
            className={classes.formUpload}
          >
            <Form.Item
              shouldUpdate={(prevValues, nextValues) =>
                prevValues.home?.imageUrl !== nextValues.home?.imageUrl
              }
            >
              {({ getFieldValue }) => (
                <>
                  <Upload
                    {...uploadImageProps}
                    onSuccess={onSuccessImageUpload}
                  />
                  <img
                    src={getFieldValue(['home', 'imageUrl'])}
                    style={{ height: 100, marginLeft: 20 }}
                    alt="Main image"
                  />
                </>
              )}
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Icône pour le navigateur"
            name={['home', 'faviconUrl']}
            className={classes.formUpload}
          >
            <Form.Item
              shouldUpdate={(prevValues, nextValues) =>
                prevValues.home?.faviconUrl !== nextValues.home?.faviconUrl
              }
            >
              {({ getFieldValue }) => (
                <>
                  <Upload
                    {...uploadImageProps}
                    onSuccess={onSuccessIconUpload}
                  />
                  <img
                    src={getFieldValue(['home', 'faviconUrl'])}
                    style={{ height: 100, marginLeft: 20 }}
                    alt="Favicon"
                  />
                </>
              )}
            </Form.Item>
          </Form.Item>
        </div>
        <Form.Item
          label="Option"
          name={['home', 'showImageOnMobile']}
          valuePropName="checked"
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Checkbox defaultChecked />
            <Box sx={{ ml: 1 }}>
              <Typography>
                Souhaitez vous afficher l&apos;image principale sur mobile ?
              </Typography>
            </Box>
          </Box>
        </Form.Item>
        <Form.Item
          label="Description"
          name={['home', 'description']}
          rules={[rules.required()]}
        >
          <TextField style={{ marginBottom: '20px' }} multiline fullWidth />
        </Form.Item>
        <Form.Item
          label="Texte du bouton"
          name={['home', 'callToAction', 'text']}
          rules={[rules.required()]}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
        <Form.Item
          label="Url du bouton"
          name={['home', 'callToAction', 'url']}
          rules={[rules.required(), rules.url()]}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section est la première qui sera présentée aux visiteurs.
              Veillez à utiliser un <b>langage commercial clair et concis</b> ,
              en insistant sur la valeur principale apportée par votre service.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Pour l&apos;affichage de votre logo, privilégiez un visuel
              contenant à la fois
              <b> le pictogramme et le nom de votre entreprise</b>.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              <b>
                L&apos;image d&apos;accueil couvre la moitié droite de
                l&apos;écran
              </b>
              , et elle est automatiquement recoupée pour s&apos;adapter à la
              taille du navigateur du visiteur, choisissez la en fonction de
              cela.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              <b>
                Nous pouvons nous occuper pour vous de produire pour vous tous
                les visuels de votre site
              </b>
              , afin qu&apos;ils soient professionnels et harmonisés.
            </Typography>
            <Button variant="outlined" style={{ marginLeft: 0 }}>
              &rarr; Commander des visuels
            </Button>
          </div>
        }
      />
    </div>
  );
};

import * as React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Card from 'src/components/basic/Card';
import { Button, Typography } from '@mui/material';
import { Flex } from 'src/components/basic/Flex';
import { Project } from 'src/slices/project';
import { CardDropDown } from './CardDropDown';
import { useHistory } from 'react-router-dom';

interface Props {
  project: Project;
}
export const ProjectCard: React.VFC<Props> = ({ project }) => {
  const history = useHistory();
  return (
    <Card
      sx={{
        border: 0,
        '& .MuiCardMedia-root': {
          objectFit: 'scale-down !important',
          margin: 'auto',
        },
      }}
    >
      <Flex sx={{ paddingBottom: 2, justifyContent: 'flex-end' }}>
        <CardDropDown project={project} />
      </Flex>
      <CardMedia
        component="img"
        height="134"
        image={
          project.published?.home?.logoUrl
            ? project.published.home.logoUrl
            : 'https://cdn-icons-png.flaticon.com/512/3988/3988027.png'
        }
        alt="Paella dish"
      />
      <Flex
        sx={{
          paddingTop: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>{project.name}</Typography>
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            history.push(`/edit/${project.slug}`);
          }}
        >
          Editer
        </Button>
      </Flex>
    </Card>
  );
};

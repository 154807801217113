import React, { useState, Dispatch, SetStateAction } from 'react';
import { Form, FormInstance } from 'antd';
import { Button, IconButton } from '@mui/material';
import { useFormRules } from '../../hooks/useFormRules';
import { defaultsUploadImageProps, Upload } from './Upload';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useStyles } from '../../constants/theme/form';
import { isImageFormatValid } from '../../utils/upload';
import { Explanations } from './Explanations';
import { TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { YesNoPopup } from './YesNoPopup';

interface Props {
  display: string;
  form: FormInstance;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
  saveDraftDebounced: (values: any) => void;
}

export const CustomerBenefits: React.VFC<Props> = ({
  display,
  form,
  setOpenInfos,
  openInfos,
  saveDraftDebounced,
}) => {
  const classes = useStyles();
  const rules = useFormRules();
  const [currentCustomer, setCurrentCustomer] = useState(0);
  const [openYesNoPopup, setOpenYesNoPopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSuccessImageUpload = (info: any) => {
    if (isImageFormatValid(info.type)) {
      enqueueSnackbar('Chargement du fichier réussi', { variant: 'success' });
      const fields = form.getFieldsValue();
      const { customerBenefits } = fields;

      const benefits = customerBenefits.benefits;
      benefits[currentCustomer] = {
        ...benefits[currentCustomer],
        imageUrl: info.response,
      };

      const updatedFields = {
        ...fields,
        customerBenefits: {
          ...customerBenefits,
          benefits,
        },
      };

      form.setFieldsValue(updatedFields);
      saveDraftDebounced(updatedFields);
    } else {
      enqueueSnackbar('Seul les formats jpeg et png sont acceptés', {
        variant: 'error',
      });
    }
  };

  const uploadImageProps = {
    ...defaultsUploadImageProps,
    onSuccess: onSuccessImageUpload,
  };

  const previewImage = (getFieldsValue: any, current: number) => {
    const fields = getFieldsValue();
    const { customerBenefits } = fields;
    if (!customerBenefits || !customerBenefits.benefits[current]) return;
    return (
      <img
        src={customerBenefits.benefits[current].imageUrl}
        style={{ height: 100, marginLeft: 20 }}
        alt="Benefit"
      />
    );
  };

  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>Bénéfices clients</Typography>
        <Form.List name={['customerBenefits', 'benefits']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key} className={classes.formSpace}>
                  <Typography className={classes.formSubtitle}>
                    {`Bénéfice client ${name + 1}`}{' '}
                  </Typography>
                  <Form.Item
                    {...restField}
                    name={[name, 'title']}
                    fieldKey={[fieldKey, 'title']}
                    label="Titre"
                    rules={[rules.required()]}
                  >
                    <TextField fullWidth size="small" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'description']}
                    fieldKey={[fieldKey, 'description']}
                    label="Détails"
                    rules={[rules.required()]}
                  >
                    <TextField fullWidth multiline />
                  </Form.Item>
                  <Form.Item
                    label="Icône"
                    {...restField}
                    name={[name, 'imageUrl']}
                    fieldKey={[fieldKey, 'imageUrl']}
                    rules={[rules.required()]}
                    className={classes.formUpload}
                  >
                    <div style={{ display: 'flex' }}>
                      <Form.Item
                        shouldUpdate={(prevValues, nextValues) =>
                          prevValues.customerBenefits?.benefits?.fieldKey
                            ?.imageUrl !==
                          nextValues.customerBenefits?.benefits?.fieldKey
                            ?.imageUrl
                        }
                        style={{ marginBottom: 0 }}
                      >
                        {({ getFieldsValue }) => (
                          <>
                            <div onClick={() => setCurrentCustomer(name)}>
                              <Upload {...uploadImageProps} />
                            </div>
                            {previewImage(getFieldsValue, name)}
                          </>
                        )}
                      </Form.Item>
                    </div>
                  </Form.Item>
                  <IconButton
                    onClick={() => setOpenYesNoPopup(true)}
                    style={{ position: 'absolute', top: 15, right: 15 }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                  <YesNoPopup
                    open={openYesNoPopup}
                    requestClose={() => setOpenYesNoPopup(false)}
                    onClickYes={() => remove(name)}
                    title={'Voulez vous vraiment supprimer?'}
                  />
                </div>
              ))}
              <Form.Item
                style={{
                  border: 'none',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => add()}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Ajouter un bénéfice client
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section vous permet de
              <b>
                {' '}
                lister tous les bénéfices qui permettent à votre solution de se
                distinguer
              </b>
              . Ici encore, restez concis et utilisez un langage commercial.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Utilisez des icônes harmonisées entre elles et avec les autres
              visuels du site.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              <b>
                Nous pouvons nous occuper pour vous de produire pour vous tous
                les visuels de votre site
              </b>
              , afin qu&apos;ils soient professionnels et harmonisés.
            </Typography>
            <Button variant="outlined" style={{ marginLeft: 0 }}>
              &rarr; Commander des visuels
            </Button>
          </div>
        }
      />
    </div>
  );
};

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography, Form } from 'antd';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FormInstance } from 'antd/es/form/Form';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory, useParams } from 'react-router-dom';
import { EditProjectButtons } from 'src/containers/editProject/EditProjectButtons';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  getProject,
  Project,
  updateDraftProject,
  updatePublishedProject,
} from 'src/slices/project';
import { IconButton } from '@mui/material';

const useStyles = createUseStyles({
  header: {
    backgroundColor: 'white',
    height: '108px',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    paddingLeft: '1vw',
    paddingRight: '1vw',
    '@media(max-width: 750px)': {
      display: 'none',
    },
  },
  headerMobile: {
    backgroundColor: 'white',
    height: '68px',
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(min-width: 751px)': {
      display: 'none',
    },
  },
  arrow: {
    color: '#222222',
    fontSize: 30,
    cursor: 'pointer',
    flexGrow: 0,
  },
  burgerButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: 'white',
    height: 'auto',
    fontSize: 30,
    '& .ant-btn:active': {
      border: 'none',
      backgroundColor: 'transparent',
      color: 'white',
    },
  },
  threeDotsButtons: {
    backgroundColor: 'transparent',
    color: 'black',
    height: 'auto',
    fontSize: '24px',
    border: 'none',
    '& .ant-btn:not([disabled]):hover': {
      border: 'none',
      backgroundColor: 'transparent',
      color: 'white',
    },
  },
  iconsMobileMenuContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
  },
  rightIconsMobileMenuContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
  },
  dropdownButton: {
    color: '#222222',
    backgroundColor: 'transparent',
    fontSize: '17px',
  },
  dropDownMenu: {
    '& .ant-dropdown-menu': {
      width: '200px !important',
    },
  },
});

const { Title } = Typography;

interface Props {
  formValidation: FormInstance;
  projectValidation: Project | null;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
  openDrawer: boolean;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
}

export const EditProjectHeader: React.VFC<Props> = ({
  formValidation,
  projectValidation,
  setOpenDrawer,
  openDrawer,
  setOpenInfos,
  openInfos,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { slug } = useParams<{ slug: string }>();
  const [isProjectLoaded, setIsProjectLoaded] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openActionsMenu = Boolean(anchorEl);
  const { project } = useAppSelector(({ project: { project } }) => ({
    project,
  }));

  const getProjectData = async () => {
    await dispatch(getProject(slug));
    form.resetFields();
    setIsProjectLoaded(true);
  };

  useEffect(() => {
    getProjectData();
  }, []);

  useEffect(() => {
    if (!project || !project.draft) return;
    return form.setFieldsValue({ ...project.draft });
  }, [isProjectLoaded]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menu = (
    <Menu
      className={classes.dropDownMenu}
      open={openActionsMenu}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
    >
      <MenuItem
        onClick={async () => {
          window.open(
            `https://sites.laserpitch.com/${project?.slug}`, // TODO: URL VAR ENV
            '_blank',
          );
          handleClose;
        }}
      >
        Voir le site
      </MenuItem>
      <MenuItem
        onClick={async () => {
          projectValidation &&
            projectValidation.published &&
            (formValidation.setFieldsValue({
              members: projectValidation.published.team?.members,
              ...projectValidation.published,
            }),
            dispatch(
              updateDraftProject({
                data: { ...form.getFieldsValue() },
                slug: slug,
              }),
            ));
          handleClose;
        }}
      >
        Annuler
      </MenuItem>
      <MenuItem
        onClick={async () => {
          formValidation
            .validateFields()
            .then(() => {
              dispatch(
                updatePublishedProject({
                  data: { ...form.getFieldsValue() },
                  slug: slug,
                }),
              );
              console.log(form.getFieldsValue());
            })
            .catch(Error);
        }}
      >
        Publier
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <div className={classes.header}>
        <ArrowBackIcon
          className={classes.arrow}
          onClick={() => history.push('/')}
        />
        <Title
          style={{
            fontSize: '36px',
            fontWeight: 900,
            lineHeight: '45px',
            marginLeft: '20px',
            flexGrow: 1,
          }}
        >
          {project?.name}
        </Title>
        <EditProjectButtons form={formValidation} project={projectValidation} />
      </div>
      <div className={classes.headerMobile}>
        <div className={classes.iconsMobileMenuContainer}>
          <MenuIcon
            className={classes.arrow}
            onClick={() => setOpenDrawer(!openDrawer)}
          />
          <ArrowBackIcon
            className={classes.arrow}
            onClick={() => history.push('/')}
          />
        </div>
        <div className={classes.rightIconsMobileMenuContainer}>
          <IconButton
            className={classes.threeDotsButtons}
            onClick={() => setOpenInfos(!openInfos)}
          >
            <InfoOutlinedIcon />
          </IconButton>
          <div style={{ color: 'black' }}>
            Actions
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              sx={{ padding: 0 }}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
            {menu}
          </div>
        </div>
      </div>
    </>
  );
};

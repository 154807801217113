import React, { useState, useEffect, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import { DeltaStatic, Sources } from 'quill';

const INPUT_DELAY = 200;

export const ReactQuillWrapper: React.VFC<ReactQuillProps> = (props) => {
  const [innerValue, setInnerValue] = useState('');

  useEffect(() => {
    if (props.value) {
      setInnerValue(props.value as string);
    } else {
      setInnerValue('');
    }
  }, [props.value]);

  const debouncedHandleOnChange = useDebouncedCallback(
    (
      value: string,
      delta: DeltaStatic,
      source: Sources,
      editor: ReactQuill.UnprivilegedEditor,
    ) => {
      if (props.onChange) {
        props.onChange(value, delta, source, editor);
      }
    },
    INPUT_DELAY,
  );

  const handleOnChange = useCallback(
    (
      value: string,
      delta: DeltaStatic,
      source: Sources,
      editor: ReactQuill.UnprivilegedEditor,
    ) => {
      const newValue = value;
      setInnerValue(newValue);
      debouncedHandleOnChange(value, delta, source, editor);
    },
    [],
  );

  return <ReactQuill {...props} value={innerValue} onChange={handleOnChange} />;
};

import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Container, Link, Stack, Typography } from '@mui/material';
import { AuthSocial } from '../components/authentication/';
import {
  AuthenticationContentStyle,
  AuthenticationStyle,
} from 'src/components/authentication/AuthenticationStyles';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import SignUpForm from 'src/containers/authentication/SignUpForm';
import { APP_NAME } from '../constants/global';

const useStyles = createUseStyles({
  registerAlreadyAnAccountText: {},
  registerLoginButton: {},
  registerContainer: {},
});

export const SignUp: React.VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <AuthenticationStyle title={`Register | ${APP_NAME}`}>
      <Container className={classes.registerContainer} maxWidth="sm">
        <AuthenticationContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {t('pages.SignUp.signUp')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {t('pages.SignUp.createAccount')}
            </Typography>
          </Stack>
          <AuthSocial />
          <SignUpForm />
          <Typography
            variant="body2"
            align="center"
            sx={{ color: 'text.secondary', mt: 3 }}
          >
            <Link underline="always" to="/" component={RouterLink}>
              {t('pages.SignUp.backToSignIn')}
            </Link>
          </Typography>
        </AuthenticationContentStyle>
      </Container>
    </AuthenticationStyle>
  );
};

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { Form } from 'antd';
import 'react-quill/dist/quill.snow.css';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useStyles } from '../../constants/theme/form';
import { useFormRules } from '../../hooks/useFormRules';
import { ReactQuillWrapper } from '../basic/ReactQuillWrapper';
import { editorFormats, editorModules } from 'src/constants/editor';
import { Explanations } from './Explanations';
import { YesNoPopup } from './YesNoPopup';

interface Props {
  display: string;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
}

export const AdditionalPages: React.VFC<Props> = ({
  display,
  setOpenInfos,
  openInfos,
}) => {
  const classes = useStyles();
  const rules = useFormRules();
  const [openYesNoPopup, setOpenYesNoPopup] = useState(false);
  const [wysiwygEditorContent, setWysiwygEditorContent] = useState('');
  //  const handleChange = (e) => {
  //    const valueEditor = e.target.value;
  //    setWysiwygEditorContent(valueEditor);
  //  };

  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>Blog</Typography>
        <Form.List name={['additionalPages', 'pages']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key} className={classes.formSpace}>
                  <Typography className={classes.formSubtitle}>
                    {`Page supplémentaire ${name + 1}`}{' '}
                  </Typography>
                  <Form.Item
                    {...restField}
                    name={[name, 'title']}
                    fieldKey={[fieldKey, 'title']}
                    label="Titre"
                    rules={[rules.required()]}
                  >
                    <TextField fullWidth size="small" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'summary']}
                    fieldKey={[fieldKey, 'summary']}
                    label="Résumé"
                    rules={[rules.required()]}
                  >
                    <TextField fullWidth multiline maxRows={4} size="small" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'description']}
                    fieldKey={[fieldKey, 'description']}
                    label="Détails"
                    rules={[rules.required()]}
                  >
                    <ReactQuillWrapper
                      theme="snow"
                      style={{ background: '#ffffff' }}
                      value={wysiwygEditorContent}
                      onChange={setWysiwygEditorContent}
                      modules={editorModules}
                      formats={editorFormats}
                    />
                  </Form.Item>
                  <IconButton
                    onClick={() => setOpenYesNoPopup(true)}
                    style={{ position: 'absolute', top: 15, right: 15 }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                  <YesNoPopup
                    open={openYesNoPopup}
                    requestClose={() => setOpenYesNoPopup(false)}
                    onClickYes={() => remove(name)}
                    title={'Voulez vous vraiment supprimer?'}
                  />
                </div>
              ))}
              <Form.Item
                style={{
                  border: 'none',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => add()}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Ajouter une page supplémentaire
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section vous permet de
              <b>
                {' '}
                lister toutes les pages qui permettent à votre solution de se
                distinguer
              </b>
              . Ici encore, restez concis et utilisez un langage commercial.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Utilisez des icônes harmonisées entre elles et avec les autres
              visuels du site.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              <b>
                Nous pouvons nous occuper pour vous de produire pour vous tous
                les visuels de votre site
              </b>
              , afin qu&apos;ils soient professionnels et harmonisés.
            </Typography>
            <Button variant="outlined" style={{ marginLeft: 0 }}>
              &rarr; Commander des visuels
            </Button>
          </div>
        }
      />
    </div>
  );
};

import React from 'react';
import { formatDistance } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  Stack,
  Link,
  Card,
  Button,
  Divider,
  Typography,
  CardHeader,
} from '@mui/material';

// ----------------------------------------------------------------------

const NEWS = [...Array(5)].map(() => {
  return {
    title: 'Title',
    description: 'Lorem ipsum',
    image: 'src/assets/dashboard/clients.png',
    postedAt: new Date(),
  };
});

// ----------------------------------------------------------------------

interface News {
  news: {
    title: string;
    description: string;
    image: string;
    postedAt: Date;
  };
}

const ContentListItem: React.VFC<News> = ({ news }) => {
  const { title, description, image, postedAt } = news;
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box
        component="img"
        alt={title}
        src={image}
        sx={{ width: 48, height: 48, borderRadius: 1.5 }}
      />
      <Box sx={{ minWidth: 240 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>
        </Link>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {description}
        </Typography>
      </Box>
      <Typography
        variant="caption"
        sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}
      >
        {formatDistance(postedAt, new Date())}
      </Typography>
    </Stack>
  );
};

const ContentList = () => {
  return (
    <Card>
      <CardHeader title="News Update" />

      <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
        {NEWS.map((news) => (
          <ContentListItem key={news.title} news={news} />
        ))}
      </Stack>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to="#"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<ArrowForwardIosIcon />}
        >
          View all
        </Button>
      </Box>
    </Card>
  );
};

export default ContentList;

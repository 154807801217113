import React, { Dispatch, SetStateAction } from 'react';
import { Form } from 'antd';
import { useStyles } from '../../constants/theme/form';
import { Explanations } from './Explanations';
import { useFormRules } from '../../hooks/useFormRules';
import { TextField, Typography } from '@mui/material';

interface Props {
  display: string;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
}

export const SpecialOffer: React.VFC<Props> = ({
  display,
  openInfos,
  setOpenInfos,
}) => {
  const classes = useStyles();
  const rules = useFormRules();
  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>Offre spéciale</Typography>
        <Form.Item label="Description" name={['specialOffer', 'text']}>
          <TextField size="small" multiline fullWidth />
        </Form.Item>
        <Form.Item
          label="Texte du bouton"
          name={['specialOffer', 'callToAction', 'text']}
        >
          <TextField size="small" multiline fullWidth />
        </Form.Item>
        <Form.Item
          label="Url du bouton"
          name={['specialOffer', 'callToAction', 'url']}
          rules={[rules.url()]}
        >
          <TextField size="small" multiline fullWidth />
        </Form.Item>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section vous permet d&apos;
              <b>ajouter un bandeau en haut de la page d&apos;accueil</b>, afin
              de signifier à vos visiteurs qu&apos;une offre temporaire est
              disponible.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              <b>Soyez concis</b>, la description doit pouvoir tenir sur une
              ligne même sur des petits écrans (mais pas sur mobile).
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Utilisez le bouton pour les diriger vers une page dédiée à cette
              offre, ou vers votre page d&apos;inscription.
            </Typography>
          </div>
        }
      />
    </div>
  );
};

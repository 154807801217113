import React, { useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { APP_NAME } from '../constants/global';
import Page from '../components/basic/Page';
import { theme } from '../constants/theme';
import { Header } from 'src/components/dashboard';
import { ProjectCard } from 'src/containers/dashboard/ProjectCard';
import { useAppSelector, useAppDispatch } from 'src/hooks/store';
import { getAll } from 'src/slices/project';

const useStyles = createUseStyles({
  dashboardContainer: {},
  dashboardGridContainer: {},
  dashboardGridItem: {},
  dashboardGridCharts: {
    marginTop: theme.spacing(2.5),
  },
});

const Projects = () => {
  const classes = useStyles();
  const { projects } = useAppSelector(({ project: { projects } }) => ({
    projects,
  }));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAll());
  }, []);

  return (
    <Page title={APP_NAME}>
      <Container className={classes.dashboardContainer} maxWidth={false}>
        <Header />
        <Grid container spacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
          {Object.values(projects.byId).map((project) => (
            <Grid
              xs={12}
              sm={6}
              md={4}
              lg={3}
              spacing={2}
              item
              key={project._id}
            >
              <ProjectCard key={project._id} project={project} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
};

export default Projects;

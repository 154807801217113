import React, { useState, Dispatch, SetStateAction } from 'react';
import { Form } from 'antd';
import { useStyles } from '../../constants/theme/form';
import { useFormRules } from '../../hooks/useFormRules';
import { Explanations } from './Explanations';
import { TextField, Typography } from '@mui/material';
import { ReactQuillWrapper } from '../basic/ReactQuillWrapper';
import { editorFormats, editorModules } from 'src/constants/editor';
import 'react-quill/dist/quill.snow.css';

interface Props {
  display: string;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
}

export const Footer: React.VFC<Props> = ({
  display,
  setOpenInfos,
  openInfos,
}) => {
  const classes = useStyles();
  const rules = useFormRules();
  const [policyWysiwygEditorContent, setPolicyWysiwygEditorContent] =
    useState('');
  const [termsWysiwygEditorContent, setTermsWysiwygEditorContent] =
    useState('');
  const [noticeWysiwygEditorContent, setNoticeWysiwygEditorContent] =
    useState('');

  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>Pied de page</Typography>
        <Form.Item
          label="Url de votre page Facebook"
          name={['footer', 'facebookUrl']}
          rules={[rules.url()]}
        >
          <TextField fullWidth size="small" />
        </Form.Item>
        <Form.Item
          label="Url de votre page Twitter"
          name={['footer', 'twitterUrl']}
          rules={[rules.url()]}
        >
          <TextField fullWidth size="small" />
        </Form.Item>
        <Form.Item
          label="Url de votre page Linkedin"
          name={['footer', 'linkedinUrl']}
          rules={[rules.url()]}
        >
          <TextField fullWidth size="small" />
        </Form.Item>
        <Form.Item
          label="Url de votre page Instagram"
          name={['footer', 'instagramUrl']}
          rules={[rules.url()]}
        >
          <TextField fullWidth size="small" />
        </Form.Item>
        <Form.Item
          label="Texte du bouton"
          name={['footer', 'callToAction', 'text']}
        >
          <TextField fullWidth size="small" />
        </Form.Item>
        <Form.Item
          label="Url du bouton"
          name={['footer', 'callToAction', 'url']}
          rules={[rules.url()]}
        >
          <TextField fullWidth size="small" />
        </Form.Item>
        <Form.Item label="Description" name={['footer', 'description']}>
          <TextField fullWidth multiline />
        </Form.Item>
        <Form.Item
          label="Politique de confidentialité"
          name={['footer', 'policy']}
        >
          <ReactQuillWrapper
            theme="snow"
            value={policyWysiwygEditorContent}
            onChange={(e) => setPolicyWysiwygEditorContent(e)}
            modules={editorModules}
            formats={editorFormats}
          />
        </Form.Item>
        <Form.Item label="Conditions d'utilisation" name={['footer', 'terms']}>
          <ReactQuillWrapper
            theme="snow"
            value={termsWysiwygEditorContent}
            onChange={(e) => setTermsWysiwygEditorContent(e)}
            modules={editorModules}
            formats={editorFormats}
          />
        </Form.Item>
        <Form.Item label="Mentions légales" name={['footer', 'notice']}>
          <ReactQuillWrapper
            theme="snow"
            value={noticeWysiwygEditorContent}
            onChange={(e) => setNoticeWysiwygEditorContent(e)}
            modules={editorModules}
            formats={editorFormats}
          />
        </Form.Item>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section vous permet de
              <b> gérer le pied de page de votre site</b>.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              <b>Toutes les informations sont optionnelles</b>, remplissez-les
              en fonction de ce qui est pertinent pour vous, le design
              s&apos;adaptera automatiquement.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Pour les champs
              <b>
                {' '}
                Politique de confidentialité, Conditions d&apos;utilisation et
                Mentions légales
              </b>
              , remplissez directement leur contenu ici et les pages
              correspondantes seront générées automatiquement
            </Typography>
          </div>
        }
      />
    </div>
  );
};

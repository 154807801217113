import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import { apiRequest } from '../helpers/api';
import { Content } from './types/content';
import { CustomDomain } from './types/customDomain';

export type Project = {
  _id: string;
  name: string;
  slug: string;
  customDomain?: CustomDomain;
  customDomainVerificationToken: string;
  draft: Content;
  published: Content;
};

interface ProjectState {
  projects: {
    byId: { [id: string]: Project };
    allIds: string[];
  };
  project: Project | null;
  loading: boolean;
  error: any;
  isSlugValid: boolean;
  isSlugChecked: boolean;
  isProjectPublished: boolean;
  formSaving: string;
}

const initialState: ProjectState = {
  projects: {
    byId: {},
    allIds: [],
  },
  project: null,
  loading: false,
  error: null,
  isSlugValid: true,
  isSlugChecked: false,
  isProjectPublished: false,
  formSaving: 'notSaving',
};

/* Thunks */

export const create = createAsyncThunk(
  'project/create',
  async (payload: { name: string; slug: string }) => {
    return await apiRequest<Project>('POST', '/project', undefined, payload);
  },
);

export const updatePublishedProject = createAsyncThunk(
  'project/updatePublishedProject',
  async (payload: { data: Content; slug: string }) => {
    return await apiRequest<Project>(
      'POST',
      `/project/update/${payload.slug}`,
      undefined,
      {
        published: {
          ...payload.data,
        },
      },
    );
  },
);

export const updateDraftProject = createAsyncThunk(
  'project/updateDraftProject',
  async (payload: { data: Content; slug: string }) => {
    return await apiRequest<Project>(
      'POST',
      `/project/update/${payload.slug}`,
      undefined,
      {
        draft: {
          ...payload.data,
        },
      },
    );
  },
);

export const updateProjectNameAndSlug = createAsyncThunk(
  'project/updateProjectNameAndSlug',
  async (data: { newName: string; newSlug: string; currentSlug: string }) => {
    const payload = {
      name: data.newName,
      slug: data.newSlug,
    };
    return await apiRequest<Project>(
      'POST',
      `/project/update/${data.currentSlug}`,
      undefined,
      payload,
    );
  },
);

export const getAll = createAsyncThunk('project/all', async () => {
  return await apiRequest<Project[]>('GET', '/project/all');
});

export const getProject = createAsyncThunk(
  'project/getProject',
  async (name: string) => {
    return await apiRequest<Project>('GET', `/project/${name}`);
  },
);

export const checkIfSlugIsValid = createAsyncThunk(
  'project/isSlugValid/:slug',
  async (slug: string) => {
    return await apiRequest<boolean>('GET', `/project/isSlugValid/${slug}`);
  },
);

export const updateIsSlugChecked = (isSlugChecked: boolean) => {
  return { type: 'updateIsSlugChecked', payload: isSlugChecked };
};

export const updateIsSlugValid = (isSlugValid: boolean) => {
  return { type: 'updateIsSlugValid', payload: isSlugValid };
};

export const updateIsProjectPublished = (isProjectPublished: boolean) => {
  return {
    type: 'updateIsProjectPublished',
    payload: isProjectPublished,
  };
};

export const updateFormSaving = (isFormSaving: string) => {
  return {
    type: 'updateIsFormSaving',
    payload: isFormSaving,
  };
};

export const deleteProject = createAsyncThunk(
  'project/:slug',
  async (slug: string) => {
    return await apiRequest<boolean>('DELETE', `/project/${slug}`);
  },
);

export const addAndVerifyCustomDomain = createAsyncThunk(
  'project/addAndVerifyCustomDomain',
  async ({ slug, domain }: { slug: string; domain: string }) => {
    return await apiRequest<Project>(
      'POST',
      `/project/${slug}/add-and-verify-domain`,
      undefined,
      { domain },
    );
  },
);

export const verifyCustomDomain = createAsyncThunk(
  'project/verifyCustomDomain',
  async (slug: string) => {
    return (
      await apiRequest<{ status: boolean }>(
        'GET',
        `/project/${slug}/verify-domain`,
      )
    ).status;
  },
);

export const deleteCustomDomain = createAsyncThunk(
  'project/deleteCustomDomain',
  async (slug: string) => {
    await apiRequest('DELETE', `/project/${slug}/delete-domain`);
  },
);

const updateIsSlugCheckedReducer = (
  action: AnyAction,
): action is PayloadAction<boolean> => {
  return action.type === 'updateIsSlugChecked';
};

const updateIsSlugValidReducer = (
  action: AnyAction,
): action is PayloadAction<boolean> => {
  return action.type === 'updateIsSlugValid';
};

const updateIsProjectPublishedReducer = (
  action: AnyAction,
): action is PayloadAction<boolean> => {
  return action.type === 'updateIsProjectPublished';
};

const updateFormSavingReducer = (
  action: AnyAction,
): action is PayloadAction<string> => {
  return action.type === 'updateIsFormSaving';
};

const projectSlice = createSlice({
  name: 'project',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, (state: ProjectState) => {
        state.loading = true;
      })
      .addCase(create.fulfilled, (state: ProjectState, { payload }) => {
        state.project = payload;
        state.loading = false;
      })
      .addCase(create.rejected, (state: ProjectState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(updatePublishedProject.pending, (state: ProjectState) => {
        state.loading = true;
      })
      .addCase(
        updatePublishedProject.fulfilled,
        (state: ProjectState, { payload }) => {
          state.project = payload;
          state.loading = false;
        },
      )
      .addCase(
        updatePublishedProject.rejected,
        (state: ProjectState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(updateProjectNameAndSlug.pending, (state: ProjectState) => {
        state.loading = true;
      })
      .addCase(
        updateProjectNameAndSlug.fulfilled,
        (state: ProjectState, { payload }) => {
          state.project = payload;
          state.loading = false;
        },
      )
      .addCase(
        updateProjectNameAndSlug.rejected,
        (state: ProjectState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(updateDraftProject.pending, (state: ProjectState) => {
        state.loading = true;
      })
      .addCase(
        updateDraftProject.fulfilled,
        (state: ProjectState, { payload }) => {
          state.project = payload;
          state.loading = false;
        },
      )
      .addCase(
        updateDraftProject.rejected,
        (state: ProjectState, { payload }) => {
          state.loading = false;
          state.error = payload;
        },
      )
      .addCase(getProject.pending, (state: ProjectState) => {
        state.loading = true;
      })
      .addCase(getProject.fulfilled, (state: ProjectState, { payload }) => {
        state.project = payload;
        state.loading = false;
      })
      .addCase(getProject.rejected, (state: ProjectState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getAll.pending, (state: ProjectState) => {
        state.loading = true;
      })
      .addCase(getAll.fulfilled, (state: ProjectState, { payload }) => {
        const projectsIds = payload.map((project) => project._id);
        const projectsById = payload.reduce(
          (accumulator, project: Project) => ({
            ...accumulator,
            [project._id]: project,
          }),
          {},
        );
        state.projects = {
          byId: { ...projectsById },
          allIds: Array.from(new Set([...projectsIds])),
        };
        state.loading = false;
      })
      .addCase(getAll.rejected, (state: ProjectState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(
        checkIfSlugIsValid.rejected,
        (state: ProjectState, { payload }) => {
          console.log(payload);
        },
      )
      .addCase(
        checkIfSlugIsValid.pending,
        (state: ProjectState, { payload }) => {
          console.log(payload);
        },
      )
      .addCase(
        checkIfSlugIsValid.fulfilled,
        (state: ProjectState, { payload }) => {
          state.isSlugValid = payload;
          state.isSlugChecked = true;
        },
      )
      .addCase(deleteProject.pending, (state: ProjectState) => {
        state.loading = true;
      })
      .addCase(deleteProject.fulfilled, (state: ProjectState) => {
        state.loading = false;
      })
      .addCase(deleteProject.rejected, (state: ProjectState) => {
        state.error = true;
      })
      .addCase(addAndVerifyCustomDomain.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(addAndVerifyCustomDomain.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.project = payload;
        state.projects.byId[payload._id] = payload;
      })
      .addCase(addAndVerifyCustomDomain.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(verifyCustomDomain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyCustomDomain.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (state.project?.customDomain) {
          state.project.customDomain.isVerified = payload;
        }
      })
      .addCase(verifyCustomDomain.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(deleteCustomDomain.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCustomDomain.fulfilled, (state) => {
        state.loading = false;
        if (state.project) {
          state.project.customDomain = undefined;
        }
      })
      .addCase(deleteCustomDomain.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addMatcher(updateIsSlugCheckedReducer, (state, action) => {
        state.isSlugChecked = action.payload;
      })
      .addMatcher(updateIsSlugValidReducer, (state, action) => {
        state.isSlugValid = action.payload;
      })
      .addMatcher(updateIsProjectPublishedReducer, (state, action) => {
        state.isProjectPublished = action.payload;
      })
      .addMatcher(updateFormSavingReducer, (state, action) => {
        state.formSaving = action.payload;
      });
  },
  initialState,
});

export default projectSlice.reducer;

import React, { Dispatch, SetStateAction, useState } from 'react';
import { Form, FormInstance } from 'antd';
import { Button, IconButton, Radio } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useFormRules } from 'src/hooks/useFormRules';
import { useStyles } from '../../constants/theme/form';
import { PriceCard } from '../../slices/types/pricing';
import { Explanations } from './Explanations';
import { TextField, Typography } from '@mui/material';
import { YesNoPopup } from './YesNoPopup';

interface Props {
  display: string;
  form: FormInstance;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
}

export const Pricing: React.VFC<Props> = ({
  display,
  form,
  openInfos,
  setOpenInfos,
}) => {
  const classes = useStyles();
  const rules = useFormRules();
  const [currentRadioField, setCurrentRadioField] = useState<number>(-1);
  const [openYesNoPopup, setOpenYesNoPopup] = useState(false);

  const handleSetAsDefaultChange = () => {
    const fields = form.getFieldsValue();
    const { pricing } = fields;
    pricing.priceCards = pricing.priceCards.map(
      (priceCard: PriceCard, priceCardIndex: number) => {
        priceCard.highlight = priceCardIndex === currentRadioField;
        return priceCard;
      },
    );
    form.setFieldsValue({ pricing });
  };

  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>Tarifs</Typography>
        <Form.List name={['pricing', 'priceCards']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key} className={classes.formSpace}>
                  <Typography className={classes.formSubtitle}>{`Tarif ${
                    name + 1
                  }`}</Typography>
                  <Form.Item
                    {...restField}
                    name={[name, 'title']}
                    fieldKey={[fieldKey, 'title']}
                    label={`Nom de l'offre (gratuit, basic, premium...)`}
                  >
                    <TextField fullWidth size="small" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'price']}
                    fieldKey={[fieldKey, 'price']}
                    label={`Prix (en chiffres, sans texte)`}
                  >
                    <TextField fullWidth size="small" />
                  </Form.Item>
                  <br />
                  <Form.Item
                    {...restField}
                    name={[name, 'details']}
                    fieldKey={[fieldKey, 'details']}
                    label={`Précisions sur le prix (mensuel, HT, ...)`}
                  >
                    <TextField fullWidth size="small" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'callToAction', 'text']}
                    fieldKey={[fieldKey, 'callToAction', 'text']}
                    label={`Texte du bouton`}
                  >
                    <TextField fullWidth size="small" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'callToAction', 'url']}
                    fieldKey={[fieldKey, 'callToAction', 'url']}
                    label={`Url du bouton`}
                    rules={[rules.url()]}
                  >
                    <TextField fullWidth size="small" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    fieldKey={[fieldKey, 'highlight']}
                    label={`Mettre cette offre en avant ?`}
                    name={[name, 'highlight']}
                    valuePropName="checked"
                  >
                    <Radio
                      checked={currentRadioField === name}
                      value={currentRadioField === name}
                      onClick={() => {
                        currentRadioField !== name
                          ? setCurrentRadioField(name)
                          : setCurrentRadioField(-1);
                        handleSetAsDefaultChange();
                      }}
                    />
                  </Form.Item>
                  <IconButton
                    onClick={() => setOpenYesNoPopup(true)}
                    style={{ position: 'absolute', top: 15, right: 15 }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                  <YesNoPopup
                    open={openYesNoPopup}
                    requestClose={() => setOpenYesNoPopup(false)}
                    onClickYes={() => remove(name)}
                    title={'Voulez vous vraiment supprimer?'}
                  />
                  <Form.List name={[name, 'benefits']}>
                    {(benefits, { add, remove }) => (
                      <div>
                        {benefits.map((benefits) => (
                          <div key={benefits.key}>
                            <Typography
                              className={classes.formSubtitle}
                            >{`Avantage ${benefits.name + 1}`}</Typography>
                            <div key={key} className={classes.formSpace}>
                              <Form.Item {...benefits} label={`Avantage`}>
                                <TextField fullWidth size="small" />
                              </Form.Item>
                              <IconButton onClick={() => remove(benefits.name)}>
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                            </div>
                          </div>
                        ))}
                        <Form.Item
                          style={{
                            border: 'none',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                          }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => fields.length < 4 && add()}
                            startIcon={<AddCircleOutlineIcon />}
                          >
                            Ajouter un avantage
                          </Button>
                        </Form.Item>
                      </div>
                    )}
                  </Form.List>
                </div>
              ))}
              <Form.Item
                style={{
                  border: 'none',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Button
                  onClick={() => fields.length < 4 && add()}
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Ajouter une carte de prix
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section vous permet de
              <b> présenter vos tarifs à vos visiteurs</b>.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Vous pouvez créer <b>une carte par type d&apos;offre</b>, et en
              mettre une de votre choix en avant pour la distinguer des autres.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Sur chaque carte, vous pouvez <b>lister les avantages</b> liés à
              l&apos;offre présentée.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Pensez à <b>vérifier le rendu des textes</b> sur le site publié.
            </Typography>
          </div>
        }
      />
    </div>
  );
};

import React, { useState, Dispatch, SetStateAction } from 'react';
import { Form, FormInstance } from 'antd';
import { Button, IconButton } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { defaultsUploadImageProps, Upload } from './Upload';
import { useStyles } from '../../constants/theme/form';
import { isImageFormatValid } from '../../utils/upload';
import { Explanations } from './Explanations';
import { TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

interface Props {
  display: string;
  form: FormInstance;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
  saveDraftDebounced: (values: any) => void;
}

export const Team: React.VFC<Props> = ({
  display,
  form,
  setOpenInfos,
  openInfos,
  saveDraftDebounced,
}) => {
  const classes = useStyles();
  const [currentMembers, setCurrentMembers] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const onSuccessImageUpload = (info: any) => {
    if (isImageFormatValid(info.type)) {
      const fields = form.getFieldsValue();
      const { team } = fields;

      const members = team.members;
      members[currentMembers] = {
        ...members[currentMembers],
        imageUrl: info.response,
      };

      const updatedFields = {
        ...fields,
        team: {
          ...team,
          members,
        },
      };

      form.setFieldsValue(updatedFields);
      saveDraftDebounced(updatedFields);
      enqueueSnackbar('Chargement du fichier réussi', { variant: 'success' });
    } else {
      enqueueSnackbar('Seul les formats jpeg et png sont acceptés', {
        variant: 'error',
      });
    }
  };

  const uploadImageProps = {
    ...defaultsUploadImageProps,
    onSuccess: onSuccessImageUpload,
  };

  const previewImage = (getFieldsValue: any, current: number) => {
    const fields = getFieldsValue();
    const { team } = fields;
    if (!team || !team.members[current]) return;
    return (
      <img
        src={team.members[current].imageUrl}
        style={{ height: 100, marginLeft: 20 }}
        alt="Team member"
      />
    );
  };

  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>Équipe</Typography>
        <Form.Item label="Punchline" name={['team', 'punchline']}>
          <TextField fullWidth size="small" />
        </Form.Item>
        <Form.List name={['team', 'members']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key}>
                  <div key={key} className={classes.formSpace}>
                    <Typography className={classes.formSubtitle}>{`Membre ${
                      name + 1
                    }`}</Typography>
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      fieldKey={[fieldKey, 'name']}
                      label={`Nom`}
                    >
                      <TextField fullWidth size="small" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'role']}
                      fieldKey={[fieldKey, 'role']}
                      label={`Rôle`}
                    >
                      <TextField fullWidth size="small" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={`Portrait`}
                      name={[name, 'imageUrl']}
                      fieldKey={[fieldKey, 'imageUrl']}
                    >
                      <div style={{ display: 'flex' }}>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, nextValues) =>
                            prevValues.team?.members?.fieldKey?.imageUrl !==
                            nextValues.team?.members?.fieldKey?.imageUrl
                          }
                        >
                          {({ getFieldsValue }) => (
                            <>
                              <div onClick={() => setCurrentMembers(name)}>
                                <Upload {...uploadImageProps} />
                              </div>
                              {previewImage(getFieldsValue, name)}
                            </>
                          )}
                        </Form.Item>
                      </div>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'details']}
                      fieldKey={[fieldKey, 'details']}
                      label="Présentation"
                    >
                      <TextField fullWidth multiline />
                    </Form.Item>
                    <IconButton
                      onClick={() => remove(name)}
                      style={{ position: 'absolute', top: 15, right: 15 }}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </div>
                </div>
              ))}
              <Form.Item
                style={{
                  border: 'none',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => add({})}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Ajouter un membre
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section vous permet de
              <b> présenter les membres de votre équipe</b>, ainsi que leur
              parcours.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              <b>Les portraits seront automatiquement recadrés</b> pour avoir
              tous la même taille, et affichés en niveau de gris avec un léger
              voile de la couleur principale de votre site, pour les harmoniser
              si ils sont trop différents.
            </Typography>
          </div>
        }
      />
    </div>
  );
};

import { Button } from '@mui/material';
import { FormInstance } from 'antd/es/form/Form';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  updatePublishedProject,
  updateDraftProject,
  Project,
  updateIsProjectPublished,
} from 'src/slices/project';
import { useSnackbar } from 'notistack';

interface Props {
  form: FormInstance;
  project: Project | null;
}

const useStyles = createUseStyles({
  uploadProject: {
    padding: '0 !important',
    border: 'none !important',
    '& .ant-btn-primary[disabled]': {
      backgroundColor: '#E8E8E8',
    },
    '& .ant-form-item-control-input': {
      minHeight: 30,
    },
  },
  uploadButtonValid: {
    padding: 20,
    marginLeft: 20,
    width: 100,
    height: 30,
  },
  uploadButtonPublished: {
    padding: 20,
    marginLeft: 20,
    width: 100,
    height: 30,
    backgroundColor: '#3edb74',
    cursor: 'grab',
    '&:hover': {
      backgroundColor: '#3edb74 !important',
      boxShadow:
        '0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%), 0px 1px 5px 0px rgb(145 158 171 / 12%)',
    },
  },
  buttonContainer: {
    marginRight: 30,
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginLeft: 20,
    height: 30,
    padding: 20,
  },
  savingText: {
    color: '#3edb74',
    marginLeft: 8,
  },
  errorText: {
    color: 'red',
    marginLeft: 8,
  },
});

export const EditProjectButtons: React.VFC<Props> = ({ form, project }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { slug } = useParams<{ slug: string }>();
  const [printPublished, setPrintPublished] = React.useState(false);
  const [printError, setPrintError] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { isProjectPublished, formSaving } = useAppSelector(
    ({ project: { isProjectPublished, formSaving } }) => ({
      isProjectPublished,
      formSaving,
    }),
  );

  const areRequiredFieldsFilled = () => {
    return !!(
      form.getFieldValue('home') &&
      form.getFieldValue('solution') &&
      form.getFieldValue('contact') &&
      form.getFieldValue('benefits')
    );
  };

  const handlePrintPublished = () => {
    setPrintPublished(true);
    setTimeout(() => setPrintPublished(false), 5000);
  };

  const handlePrintError = () => {
    setPrintError(true);
    setTimeout(() => setPrintError(false), 5000);
  };

  return (
    <div className={classes.buttonContainer}>
      {formSaving === 'saving' ? (
        <div className={classes.savingText}>Sauvegarde en cours ...</div>
      ) : (
        <></>
      )}
      {formSaving === 'saved' && !printPublished && !printError ? (
        <div className={classes.savingText}>Sauvegardé.</div>
      ) : (
        <></>
      )}
      {printPublished ? (
        <div className={classes.savingText}>
          Publié. (attendre 10 secondes pour voir les modifications)
        </div>
      ) : (
        <></>
      )}
      {printError ? (
        <div className={classes.errorText}>
          Erreur lors de la publication de vos modifications
        </div>
      ) : (
        <></>
      )}
      <Button
        variant="outlined"
        disabled={!project?.published}
        onClick={async () => {
          window.open(
            `https://sites.laserpitch.com/${project?.slug}`,
            '_blank',
          );
        }}
        className={classes.button}
      >
        Voir le site
      </Button>
      <Button
        variant="outlined"
        className={classes.button}
        disabled={!project?.published}
        color="error"
        onClick={async () => {
          project &&
            project.published &&
            (form.setFieldsValue({
              ...project.published,
            }),
            dispatch(
              updateDraftProject({
                data: { ...form.getFieldsValue() },
                slug: slug,
              }),
            ));
        }}
      >
        Annuler
      </Button>
      <Button
        variant="contained"
        className={
          !isProjectPublished
            ? classes.uploadButtonValid
            : classes.uploadButtonPublished
        }
        color="info"
        onClick={async (event) => {
          !isProjectPublished
            ? form
                .validateFields()
                .then(async (values) => {
                  console.log(values);
                  const result = await dispatch(
                    updatePublishedProject({
                      data: { ...form.getFieldsValue() },
                      slug: slug,
                    }),
                  );
                  if (result.type.includes('fulfilled')) {
                    dispatch(updateIsProjectPublished(true));
                    handlePrintPublished();
                  } else {
                    handlePrintError();
                  }
                })
                .catch((error) => {
                  const firstError = error.errorFields[0];
                  if (firstError) {
                    enqueueSnackbar(
                      `${firstError.errors[0]}: ${firstError.name.join('.')}`,
                      { variant: 'error' },
                    );
                  }
                })
            : event.preventDefault();
        }}
        disabled={
          areRequiredFieldsFilled() ||
          project?.draft === project?.published ||
          form.getFieldsError().filter(({ errors }) => errors.length).length > 0
        }
      >
        {!isProjectPublished ? 'Publier' : 'Publié'}
      </Button>
    </div>
  );
};

export const editorModules: any = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ color: [] }],
      ['link', 'image'],
      ['clean'],
    ],
    handlers: {
      image: function (this: any) {
        const range = this.quill.getSelection();
        const value = prompt("Veuillez insérer l'URL de l'image");
        if (value) {
          this.quill.insertEmbed(range.index, 'image', value, 'user');
        }
      },
    },
  },
};
export const editorFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'color',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

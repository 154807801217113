import React, { Dispatch, SetStateAction } from 'react';
import { createUseStyles } from 'react-jss';
import { Typography, Drawer } from '@mui/material';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    height: 'calc(100vh - 118px)',
    '& .ant-form-item-label': {
      fontWeight: 'bold',
    },
    '@media(max-width: 750px)': {
      height: 'auto',
    },
  },
  explanationsContainer: {
    width: '35%',
    backgroundColor: 'white',
    height: 'calc(100vh - 200px)',
    overflow: 'auto',
    marginTop: '1vw',
    marginLeft: '2vw',
    paddingRight: 30,
    '@media(max-width: 750px)': {
      display: 'none',
    },
  },
  explanationsMobileContainer: {
    '@media(min-width: 751px)': {
      display: 'none',
    },
  },
  explanationsTitle: {
    fontSize: '24px',
    lineHeight: '30px',
    marginBottom: '40px',
    fontWeight: 'bolder',
  },
  explanationsTitleMobile: {
    fontSize: '24px',
    lineHeight: '30px',
    margin: 'auto',
    fontWeight: 'bolder',
    marginBottom: '40px',
  },
});

interface Props {
  text?: React.ReactNode;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
  display: string;
}

export const Explanations: React.VFC<Props> = ({
  text,
  setOpenInfos,
  openInfos,
  display,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.explanationsContainer}>
        <Typography className={classes.explanationsTitle}>
          Explications
        </Typography>
        <div>{text}</div>
      </div>
      <div className={classes.explanationsMobileContainer}>
        <Drawer
          open={openInfos && display !== 'none'}
          onClose={() => setOpenInfos(false)}
          anchor="right"
          sx={{
            '& .MuiPaper-root': {
              padding: '20px',
              width: '70%',
            },
          }}
        >
          <div>
            <Typography className={classes.explanationsTitleMobile}>
              Explications
            </Typography>
            <div>{text}</div>
          </div>
        </Drawer>
      </div>
    </>
  );
};

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LoggedLayout } from 'src/containers/LoggedLayout';
import Projects from 'src/pages/Projects';
import { Form } from 'src/pages/Form';
// import { useWebSocket } from 'src/hooks/useWebSocket';

export const LoggedSwitch: React.VFC = () => {
  /* If you doesn't need websocket connection, you can remove useWebSocket */
  // useWebSocket({
  //   /* template: registerLiveTemplate */
  // });

  return (
    <LoggedLayout>
      <Switch>
        <Route exact path="/" component={Projects} />
        <Route exact path="/dashboard" component={Projects} />
        <Route exact path="/edit/:slug" component={Form} />
        <Redirect to="/" />
      </Switch>
    </LoggedLayout>
  );
};

import React from 'react';

import { Container, Stack, Typography } from '@mui/material';
import { AuthSocial } from '../components/authentication/';
import {
  AuthenticationContentStyle,
  AuthenticationStyle,
} from 'src/components/authentication/AuthenticationStyles';
import { useTranslation } from 'react-i18next';
import SignInForm from 'src/containers/authentication/SignInForm';
import { APP_NAME } from '../constants/global';

export const SignIn: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <AuthenticationStyle title={`SignIn | ${APP_NAME}`}>
      <Container maxWidth="sm">
        <AuthenticationContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {t('pages.SignIn.signIn')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {t('pages.SignIn.enterDetails')}
            </Typography>
          </Stack>
          <AuthSocial />
          <SignInForm />
        </AuthenticationContentStyle>
      </Container>
    </AuthenticationStyle>
  );
};

import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../slices/auth';
import projectReducer from '../slices/project';

/* Reducers */
const rootReducer = combineReducers({
  auth: authReducer,
  project: projectReducer,
});

export const store = configureStore({ reducer: rootReducer });

/* Infer the `RootState` and `AppDispatch` types from the store itself */
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

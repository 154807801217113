import React, { Dispatch, SetStateAction } from 'react';
import { Form } from 'antd';
import { useStyles } from '../../constants/theme/form';
import { Explanations } from './Explanations';
import { useFormRules } from '../../hooks/useFormRules';
import { TextField, Typography } from '@mui/material';

interface Props {
  display: string;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
}

export const Login: React.VFC<Props> = ({
  display,
  openInfos,
  setOpenInfos,
}) => {
  const classes = useStyles();
  const rules = useFormRules();
  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>Authentification</Typography>
        <Form.Item
          label="Texte du bouton 1 (connexion)"
          name={['login', 'signIn', 'text']}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
        <Form.Item
          label="Url du bouton 1"
          name={['login', 'signIn', 'url']}
          rules={[rules.url()]}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
        <Form.Item
          label="Texte du bouton 2 (inscription)"
          name={['login', 'signUp', 'text']}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
        <Form.Item
          label="Url du bouton 2"
          name={['login', 'signUp', 'url']}
          rules={[rules.url()]}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section vous permet d&apos;
              <b>
                ajouter des boutons en haut à gauche de la page d&apos;accueil
              </b>
              , notamment pour permettre à vos clients de s&apos;inscrire ou de
              se connecter.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Mais vous pouvez aussi utiliser ces boutons pour d&apos;autres
              fonctionnalités, comme prendre rendez-vous, etc...
            </Typography>
          </div>
        }
      />
    </div>
  );
};

import React, { Dispatch, SetStateAction } from 'react';
import { Form } from 'antd';
import { useFormRules } from '../../hooks/useFormRules';
import { useStyles } from '../../constants/theme/form';
import { Explanations } from './Explanations';
import { TextField, Typography } from '@mui/material';

interface Props {
  display: string;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
}

export const Contact: React.VFC<Props> = ({
  display,
  openInfos,
  setOpenInfos,
}) => {
  const rules = useFormRules();
  const classes = useStyles();
  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>Contact</Typography>
        <Form.Item
          label="Nom"
          name={['contact', 'name']}
          rules={[rules.required()]}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
        <Form.Item
          label="Téléphone"
          name={['contact', 'phone']}
          rules={[rules.required()]}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
        <Form.Item
          label="Email"
          name={['contact', 'email']}
          rules={[rules.email(), rules.required()]}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section
              <b> ne nécessite vraisemblablement pas d&apos;explications</b> ;)
            </Typography>
          </div>
        }
      />
    </div>
  );
};

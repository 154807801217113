import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Divider, InputAdornment, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Form, FormikProvider } from 'formik';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  checkIfSlugIsValid,
  updateIsSlugChecked,
  updateIsSlugValid,
} from 'src/slices/project';
import { debounce } from 'lodash';

const useStyles = createUseStyles({
  container: {},
  button: {
    fontWeight: 600,
    color: '#FFF',
    backgroundColor: '#143779',
    fontSize: '16px',
    height: '45px',
    borderRadius: '10px',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
      color: 'white',
      backgroundColor: 'black',
      borderColor: 'black',
    },
  },
  submitButton: {
    '&:hover': {
      cursor: 'pointer',
      color: 'white',
      backgroundColor: 'black',
      borderColor: 'black',
    },
  },
  cancelButton: {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      cursor: 'pointer',
      color: 'white',
      backgroundColor: 'black',
      borderColor: 'black',
    },
  },
});

interface Props {
  formik: any;
  slugToIgnore?: string;
  newProject?: boolean;
}

export const ProjectSlug: React.VFC<Props> = ({
  formik,
  slugToIgnore,
  newProject,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isSlugValid } = useAppSelector(({ project: { isSlugValid } }) => ({
    isSlugValid,
  }));

  const { handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    if (slugToIgnore && slugToIgnore !== formik.values.slug)
      dispatch(checkIfSlugIsValid(formik.values.slug));
  }, [formik.values.slug]);

  useEffect(() => {
    if (slugToIgnore && slugToIgnore === formik.values.slug) {
      console.log(slugToIgnore, formik.values.slug);
      dispatch(updateIsSlugValid(true));
      return;
    }
  }, []);

  const projectNameToSlug = (currentProjectSlug: string) => {
    const newSlug = currentProjectSlug
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
    formik.setFieldValue('slug', newSlug);
    return newSlug;
  };

  const handleSlugAvailibility = () => {
    const newSlug = formik.values.slug;
    if (slugToIgnore && slugToIgnore === newSlug) {
      dispatch(updateIsSlugValid(true));
      return;
    }
    dispatch(updateIsSlugChecked(false));
  };

  const debounced = debounce(() => handleSlugAvailibility(), 450, {
    maxWait: 1000,
  });

  const handleProjectSlugChange = (currentProjectSlug: string) => {
    if (slugToIgnore && slugToIgnore === currentProjectSlug) {
      dispatch(updateIsSlugValid(true));
      return;
    }
    dispatch(updateIsSlugChecked(false));
    dispatch(checkIfSlugIsValid(currentProjectSlug));
  };

  return (
    <div className={classes.container}>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={3}>
              <TextField
                label={'Nom du projet'}
                name="name"
                {...getFieldProps('name')}
                onChange={(e) => {
                  handleProjectSlugChange(
                    projectNameToSlug(e.currentTarget.value),
                  );
                  setFieldValue('name', e.currentTarget.value);
                }}
                maxLength={150}
              />
              <TextField
                label={'URL'}
                name="slug"
                {...getFieldProps('slug')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      app.laserpitch.com/
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  debounced();
                  setFieldValue('slug', e.currentTarget.value);
                }}
                maxLength={150}
                error={!isSlugValid}
                helperText={!isSlugValid ? 'Ce nom de domaine existe déjà' : ''}
              />
              {newProject && (
                <DialogContentText>
                  Note: Il est possible de choisir un nom de domaine
                  personnalisé par la suite.
                </DialogContentText>
              )}
            </Stack>
          </DialogContent>
          <Divider />
        </Form>
      </FormikProvider>
    </div>
  );
};

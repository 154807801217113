import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SignIn } from '../pages/SignIn';
import { SignUp } from '../pages/SignUp';
import { LostPassword } from '../pages/LostPassword';

export const GuestSwitch: React.VFC = () => {
  return (
    <Switch>
      <Route path={['/', '/signIn']} exact component={SignIn} />
      <Route path="/signUp" component={SignUp} />
      <Route path="/lostPassword" component={LostPassword} />
      <Redirect to="/" />
    </Switch>
  );
};

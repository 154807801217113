import React, { useState, Dispatch, SetStateAction } from 'react';
import { Form, FormInstance } from 'antd';
import { Button, IconButton } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { defaultsUploadImageProps, Upload } from './Upload';
import { useStyles } from '../../constants/theme/form';
import { isImageFormatValid } from '../../utils/upload';
import { Explanations } from './Explanations';
import { TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

interface Props {
  display: string;
  form: FormInstance;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
  saveDraftDebounced: (values: any) => void;
}

export const Testimonials: React.VFC<Props> = ({
  display,
  form,
  setOpenInfos,
  openInfos,
  saveDraftDebounced,
}) => {
  const classes = useStyles();
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const onSuccessImageUpload = (info: any) => {
    if (isImageFormatValid(info.type)) {
      enqueueSnackbar('Chargement du fichier réussi', { variant: 'success' });
      const fields = form.getFieldsValue();
      const { testimonials } = fields;

      const customerTestimonials = testimonials.customerTestimonials;
      customerTestimonials[currentTestimonial] = {
        ...customerTestimonials[currentTestimonial],
        imageUrl: info.response,
      };

      const updatedFields = {
        ...fields,
        testimonials: {
          ...testimonials,
          customerTestimonials,
        },
      };

      form.setFieldsValue(updatedFields);
      saveDraftDebounced(updatedFields);
    } else {
      enqueueSnackbar('Seul les formats jpeg et png sont acceptés', {
        variant: 'error',
      });
    }
  };

  const uploadImageProps = {
    ...defaultsUploadImageProps,
    onSuccess: onSuccessImageUpload,
  };

  const previewImage = (getFieldsValue: any, current: number) => {
    const fields = getFieldsValue();
    const { testimonials } = fields;
    if (!testimonials || !testimonials.customerTestimonials[current]) return;
    return (
      <img
        src={testimonials.customerTestimonials[current].imageUrl}
        style={{ height: 100, marginLeft: 20 }}
        alt="Testimonial text"
      />
    );
  };

  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>
          Témoignages clients
        </Typography>
        <Form.Item label="Punchline" name={['testimonials', 'title']}>
          <TextField fullWidth size="small" />
        </Form.Item>
        <Form.Item label="Sous-titre" name={['testimonials', 'subtitle']}>
          <TextField fullWidth size="small" />
        </Form.Item>
        <Form.Item label="Description" name={['testimonials', 'details']}>
          <TextField fullWidth size="small" />
        </Form.Item>
        <Form.List name={['testimonials', 'customerTestimonials']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key} className={classes.formSpace}>
                  <Typography className={classes.formSubtitle}>{`Témoignage ${
                    name + 1
                  }`}</Typography>
                  <Form.Item
                    {...restField}
                    name={[name, 'punchline']}
                    fieldKey={[fieldKey, 'punchline']}
                    label={`Nom du client`}
                  >
                    <TextField fullWidth size="small" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'subtitle']}
                    fieldKey={[fieldKey, 'subtitle']}
                    label={`Entreprise et rôle`}
                  >
                    <TextField fullWidth size="small" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label={`Portrait ou logo`}
                    name={[name, 'imageUrl']}
                    fieldKey={[fieldKey, 'imageUrl']}
                  >
                    <div style={{ display: 'flex' }}>
                      <Form.Item
                        shouldUpdate={(prevValues, nextValues) =>
                          prevValues.testimonials?.customerTestimonials
                            ?.fieldKey?.imageUrl !==
                          nextValues.testimonials?.customerTestimonials
                            ?.fieldKey?.imageUrl
                        }
                      >
                        {({ getFieldsValue }) => (
                          <>
                            <div onClick={() => setCurrentTestimonial(name)}>
                              <Upload {...uploadImageProps} />
                            </div>
                            {previewImage(getFieldsValue, name)}
                          </>
                        )}
                      </Form.Item>
                    </div>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'description']}
                    fieldKey={[fieldKey, 'description']}
                    label="Texte du témoignage"
                  >
                    <TextField fullWidth multiline />
                  </Form.Item>
                  <IconButton
                    onClick={() => remove(name)}
                    style={{ position: 'absolute', top: 15, right: 15 }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </div>
              ))}
              <Form.Item
                style={{
                  border: 'none',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => add()}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Ajouter un témoignage client
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section vous permet d&apos;
              <b>afficher des témoignages de vos clients</b>.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Vous avez le choix d&apos;ajouter ou non des informations
              générales sur cette section (titre, sous-titre, description).
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Veillez à garder une longueur similaire pour tous les textes des
              témoignages.
            </Typography>
          </div>
        }
      />
    </div>
  );
};

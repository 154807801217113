import React, { useState, Dispatch, SetStateAction } from 'react';
import { Form } from 'antd';
import { Button, IconButton } from '@mui/material';
import { FormInstance } from 'antd/es/form/Form';
import { defaultsUploadImageProps, Upload } from './Upload';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useStyles } from '../../constants/theme/form';
import { isImageFormatValid } from '../../utils/upload';
import { Explanations } from './Explanations';
import { TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { YesNoPopup } from './YesNoPopup';

interface Props {
  display: string;
  form: FormInstance;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
  saveDraftDebounced: (values: any) => void;
}

export const References: React.VFC<Props> = ({
  display,
  form,
  openInfos,
  setOpenInfos,
  saveDraftDebounced,
}) => {
  const classes = useStyles();
  const [currentReferences, setCurrentReferences] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [openYesNoPopup, setOpenYesNoPopup] = useState(false);

  const onSuccessImageUpload = (info: any) => {
    if (isImageFormatValid(info.type)) {
      enqueueSnackbar('Chargement du fichier réussi', { variant: 'success' });
      const fields = form.getFieldsValue();

      const references = fields.references.references;
      references[currentReferences] = {
        ...references[currentReferences],
        logoUrl: info.response,
      };

      const updatedFields = {
        ...fields,
        references: {
          ...fields.references,
          references,
        },
      };

      form.setFieldsValue(updatedFields);
      saveDraftDebounced(updatedFields);
    } else {
      enqueueSnackbar('Seul les formats jpeg et png sont acceptés', {
        variant: 'error',
      });
    }
  };
  const uploadImageProps = {
    ...defaultsUploadImageProps,
    onSuccess: onSuccessImageUpload,
  };

  const previewImage = (getFieldsValue: any, current: number) => {
    const fields = getFieldsValue();
    const references = fields.references?.references;
    if (!references || !references[current]) return;
    return (
      <img
        src={references[current].logoUrl}
        style={{ height: 100, marginLeft: 20 }}
        alt="Client logo"
      />
    );
  };

  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>Références</Typography>
        <Form.List name={['references', 'references']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key} className={classes.formSpace}>
                  <Typography className={classes.formSubtitle}>{`Client ${
                    name + 1
                  }`}</Typography>
                  <Form.Item
                    {...restField}
                    label={`Logo de l'entreprise`}
                    name={[name, 'logoUrl']}
                    fieldKey={[fieldKey, 'logoUrl']}
                    style={{ marginBottom: 0 }}
                  >
                    <div style={{ display: 'flex' }}>
                      <Form.Item
                        shouldUpdate={(prevValues, nextValues) =>
                          prevValues.references?.references?.fieldKey
                            ?.logoUrl !==
                          nextValues.references?.references?.fieldKey?.logoUrl
                        }
                      >
                        {({ getFieldsValue }) => (
                          <>
                            <div onClick={() => setCurrentReferences(name)}>
                              <Upload {...uploadImageProps} />
                            </div>
                            {previewImage(getFieldsValue, name)}
                          </>
                        )}
                      </Form.Item>
                    </div>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    fieldKey={[fieldKey, 'name']}
                    label={`Nom de l'entreprise (affiché au survol seulement)`}
                  >
                    <TextField fullWidth size="small" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'url']}
                    fieldKey={[fieldKey, 'url']}
                    label={`Url (lien du clic sur le logo)`}
                  >
                    <TextField fullWidth size="small" />
                  </Form.Item>
                  <IconButton
                    onClick={() => setOpenYesNoPopup(true)}
                    style={{ position: 'absolute', top: 15, right: 15 }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                  <YesNoPopup
                    open={openYesNoPopup}
                    requestClose={() => setOpenYesNoPopup(false)}
                    onClickYes={() => remove(name)}
                    title={'Voulez vous vraiment supprimer?'}
                  />
                </div>
              ))}
              <Form.Item
                style={{
                  border: 'none',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => add()}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Ajouter une référence
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section vous permet d&apos;
              <b>ajouter une liste horizontale des logos de vos clients</b>,
              juste en dessous de la page d&apos;accueil.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Les logos seront <b>affichés en niveaux de gris</b> pour
              harmoniser le rendu visuel.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Utilisez les liens si vous voulez diriger vos visiteurs vers le
              site du client ou vers un case-study.
            </Typography>
          </div>
        }
      />
    </div>
  );
};

import { message } from 'antd';

export const errorOnUpload = () => {
  message.error('Erreur lors du chargement du fichier');
};
export const invalidFormatOnUpload = () => {
  message.error('Seul les formats jpeg et png sont acceptés');
};
export const invalidFavicon = () => {
  message.error('Seul le format png est accepté');
};
export const successOnUpload = () => {
  message.success('Chargement du fichier réussi');
};

export const isFavicon = (infoType: any): boolean => {
  return infoType === 'image/png';
};

export const isImageFormatValid = (infoType: any): boolean => {
  return (
    infoType === 'image/png' ||
    infoType === 'image/jpeg' ||
    infoType === 'image/webp' ||
    infoType === 'image/gif' ||
    infoType === 'image/svg+xml'
  );
};

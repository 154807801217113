import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  DialogTitle,
  TextField,
  Typography,
  Stack,
} from '@mui/material';

import { useStyles } from '../../constants/theme/form';
import { Explanations } from '../../components/form/Explanations';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  addAndVerifyCustomDomain,
  deleteCustomDomain,
  verifyCustomDomain,
} from '../../slices/project';

interface IProps {
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
}

const RECORD_TXT_VARIABLE_NAME = 'laserpitch-domain-verification';
const FQDN_REGEX =
  /^(?!.*?_.*?)(?!(?:[\w]+?\.)?\-[\w\.\-]*?)(?![\w]+?\-\.(?:[\w\.\-]+?))(?=[\w])(?=[\w\.\-]*?\.+[\w\.\-]*?)(?![\w\.\-]{254})(?!(?:\.?[\w\-\.]*?[\w\-]{64,}\.)+?)[\w\.\-]+?(?<![\w\-\.]*?\.[\d]+?)(?<=[\w\-]{2,})(?<![\w\-]{25})$/;

export const CustomDomain: React.VFC<IProps> = ({
  setOpenInfos,
  openInfos,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { customDomainVerificationToken, slug, customDomain } =
    useAppSelector(({ project }) => project.project) || {};

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [domainInput, setDomainInput] = useState('');
  const [isDomainInputError, setIsDomainInputError] = useState(true);

  const dialogDomain = () => (
    <Dialog open={isDialogOpen}>
      <DialogTitle>Ajouter un nom de domaine personnalisé</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={4}>
          <DialogContentText>
            <Stack spacing={2}>
              <Typography>
                Entrez le nom de domaine que vous souhaitez lier.
              </Typography>
              <Typography color="red">
                N&apos;oubliez d&apos;ajouter l&apos;enregistrement TXT sur
                votre domaine: <br />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5px',
                  }}
                >
                  <b>
                    <code>{`${RECORD_TXT_VARIABLE_NAME}=${customDomainVerificationToken}`}</code>
                  </b>
                </div>
              </Typography>
            </Stack>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="domain"
            label="Nom de domaine"
            type="text"
            fullWidth
            error={isDomainInputError}
            // variant="standard"
            value={domainInput}
            onChange={(e) => {
              setDomainInput(e.currentTarget.value);
              if (FQDN_REGEX.test(e.currentTarget.value)) {
                setIsDomainInputError(false);
              } else {
                setIsDomainInputError(true);
              }
            }}
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            setIsDialogOpen(false);
            setDomainInput('');
            setIsDomainInputError(true);
          }}
        >
          Annuler
        </Button>
        <Button
          disabled={isDomainInputError}
          onClick={() => {
            setIsDialogOpen(false);
            dispatch(
              addAndVerifyCustomDomain({
                slug: slug || '',
                domain: domainInput,
              }),
            );
            setDomainInput('');
            setIsDomainInputError(true);
          }}
          type="submit"
          variant="contained"
          className={classes.submitButton}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Typography className={classes.formTitle}>
            Nom de domaine personnalisé
          </Typography>
          <Chip
            color={customDomain?.isVerified ? 'success' : 'error'}
            label={
              customDomain?.isVerified ? 'Domaine actif' : 'Domaine non vérifié'
            }
            size="small"
          />
        </Stack>
        <div id="domainContainer" style={{ flexDirection: 'column' }}>
          <Typography
            color={customDomain?.domain ? '' : 'error'}
            sx={{ marginBottom: '10px' }}
          >
            {customDomain?.domain || "Vous n'avez pas lié de nom de domaine"}
          </Typography>
          {customDomain && !customDomain.isVerified && (
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(verifyCustomDomain(slug || ''));
              }}
              sx={{ marginTop: '10px' }}
            >
              Vérifier le domaine
            </Button>
          )}
        </div>
        <Stack spacing={2} direction="row" sx={{ marginTop: '10px' }}>
          <Button
            variant="outlined"
            onClick={() => {
              setIsDialogOpen(true);
            }}
          >
            {customDomain?.domain ? 'Modifier le domaine' : 'Lier un domaine'}
          </Button>
          {customDomain?.domain && (
            <Button
              color="error"
              variant="outlined"
              onClick={() => {
                dispatch(deleteCustomDomain(slug || ''));
              }}
            >
              Supprimer le domaine
            </Button>
          )}
        </Stack>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display=""
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section vous permet de
              <b> définir un nom de domaine personnalisé</b> (monnomdedomaine.fr
              par exemple).
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Veuillez noter que <b>cette fonctionnalité sera payante</b> dans
              le futur.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              <b>Si vous avez déjà un nom de domaine</b>, une fois que vous
              l&apos;aurez renseigné ici, il vous faudra éditer ses champs DNS
              pour l&apos;associer à Laserpitch.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Plus précisément, il vous faudra rajouter à la zone DNS de votre
              domaine une entrée{' '}
              <b>
                <code>A</code>
              </b>{' '}
              avec la valeur suivante :{' '}
              <b>
                <code>15.188.203.61</code>
              </b>
              .
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Vous devez également ajouter une entrée TXT avec la valeur
              suivante:{' '}
              <b>
                <code>
                  {RECORD_TXT_VARIABLE_NAME}={customDomainVerificationToken}
                </code>
              </b>
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              Si vous ne savez pas comment faire, référez-vous aux instructions
              fournies par votre fournisseur de nom de domaine, ou
              contactez-nous.
            </Typography>
            <Typography style={{ marginBottom: 20 }}>
              <b>Si vous n&apos;avez pas de nom de domaine</b>, vous pouvez en
              acheter un directement via Laserpitch.
            </Typography>
            <Button variant="outlined" style={{ marginLeft: 0 }}>
              &rarr; Commander un nom de domaine
            </Button>
          </div>
        }
      />
      {dialogDomain()}
    </div>
  );
};

import * as Yup from 'yup';
import React, { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import { signUp } from 'src/slices/auth';

const useStyles = createUseStyles({
  registerForm: {
    '& .MuiOutlinedInput-input': {
      padding: '16.5px 14px',
    },
    '& .MuiIconButton-root': {
      padding: '20px',
    },
  },
  registerFormContainer: {},
  registerNameContainer: {},
  registerFirstName: {},
  registerLastName: {},
  registerEmail: {},
  registerPassword: {},
  registerSubmitButton: {},
});

const SignUpForm: React.VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t('forms.rules.min'))
      .max(50, t('forms.rules.max'))
      .required(t('forms.rules.required')),
    lastName: Yup.string()
      .min(2, t('forms.rules.min'))
      .max(50, t('forms.rules.max'))
      .required(t('forms.rules.required')),
    email: Yup.string()
      .email(t('forms.rules.email'))
      .required(t('forms.rules.required')),
    password: Yup.string().required(t('forms.rules.required')),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        t('forms.errorConfirmPasswordNotMatch'),
      )
      .required(t('forms.rules.required')),
  });
  const [error, setError] = useState<string | undefined>(undefined);
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      const { email, password, firstName, lastName } = values;
      if (error) {
        setError(undefined);
      }
      const resultAction = await dispatch(
        signUp({ email, password, firstName, lastName }),
      );
      if (signUp.rejected.match(resultAction)) {
        setError(resultAction.error.message);
      }
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form className={classes.registerForm} noValidate onSubmit={handleSubmit}>
        <Stack className={classes.registerFormContainer} spacing={3}>
          <Stack
            className={classes.registerNameContainer}
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
          >
            <TextField
              className={classes.registerFirstName}
              fullWidth
              label={t('common.firstName')}
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              className={classes.registerLastName}
              fullWidth
              label={t('common.lastName')}
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField
            className={classes.registerEmail}
            fullWidth
            type="email"
            label={t('common.email')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            className={classes.registerPassword}
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label={t('common.password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            className={classes.registerPassword}
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label={t('forms.confirmPassword')}
            {...getFieldProps('confirmPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />

          <LoadingButton
            className={classes.registerSubmitButton}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('common.register')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default SignUpForm;

import React, { Dispatch, SetStateAction } from 'react';
import { Form } from 'antd';
import { useStyles } from '../../constants/theme/form';
import { Explanations } from './Explanations';
import { TextField, Typography } from '@mui/material';

interface Props {
  display: string;
  setOpenInfos: Dispatch<SetStateAction<boolean>>;
  openInfos: boolean;
}

export const GoogleAnalytics: React.VFC<Props> = ({
  display,
  openInfos,
  setOpenInfos,
}) => {
  const classes = useStyles();
  return (
    <div style={{ display: display }} className={classes.container}>
      <div className={classes.formContainer}>
        <Typography className={classes.formTitle}>Google Analytics</Typography>
        <Form.Item
          label="Identifiant Google Analytics (gtag)"
          name={['options', 'gtag']}
        >
          <TextField size="small" fullWidth />
        </Form.Item>
      </div>
      <Explanations
        openInfos={openInfos}
        setOpenInfos={setOpenInfos}
        display={display}
        text={
          <div>
            <Typography style={{ marginBottom: 20 }}>
              Cette section vous permet d&apos;ajouter
              <b> Google Analytics </b>
              sur votre site
            </Typography>
          </div>
        }
      />
    </div>
  );
};

import React, { useState } from 'react';
import { Stack, Button, Divider, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { theme } from '../../constants/theme';
import { GoogleLogin } from 'react-google-login';
import { Google } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/store';
import { signInUpGoogle } from 'src/slices/auth';

const useStyles = createUseStyles({
  authSocialContainer: {},
  authSocialButtons: {
    fontSize: 'large',
  },
  googleButton: {
    color: '#DF3E30',
    height: '24px',
  },
  facebookButton: {
    color: '#1877F2',
    height: '24px',
  },
  twitterButton: {
    color: '#1C9CEA',
    height: '24px',
  },
  authSocialDivider: {
    margin: theme.spacing(3, 0),
  },
  authSocialOrDivider: {
    color: theme.palette.text.secondary,
  },
});

// ----------------------------------------------------------------------

const AuthSocial: React.VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string | undefined>(undefined);
  const responseGoogle = async (response: any) => {
    if (error) {
      setError(undefined);
    }

    if (response.tokenId) {
      const resultAction = await dispatch(
        signInUpGoogle({ googleAccessToken: response.tokenId }),
      );

      if (signInUpGoogle.rejected.match(resultAction)) {
        setError(resultAction.error.message);
      }
    }
  };
  return (
    <div className={classes.authSocialContainer}>
      <Stack direction="row" spacing={2} className={classes.authSocialButtons}>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              variant="outlined"
              startIcon={<Google />}
              fullWidth
            >
              {t('pages.SignIn.loginWithGoogle')}
            </Button>
          )}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        />
      </Stack>

      <Divider className={classes.authSocialDivider}>
        <Typography
          variant="body2"
          className={classes.authSocialOrDivider}
          sx={{ textTransform: 'uppercase' }}
        >
          {t('common.or')}
        </Typography>
      </Divider>
    </div>
  );
};

export default AuthSocial;

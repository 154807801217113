import * as Yup from 'yup';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { theme } from '../../constants/theme';
import { useAppDispatch } from 'src/hooks/store';
import { signIn } from 'src/slices/auth';

const useStyles = createUseStyles({
  signInForm: {
    '& .MuiOutlinedInput-input': {
      padding: '16.5px 14px',
    },
    '& .MuiIconButton-root': {
      padding: '20px',
    },
  },
  signInFormContainer: {},
  signInEmail: {},
  signInPassword: {},
  signInActionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0),
  },
  signInActionsRemember: {},
  signInActionsForgotPassword: {},
  signInSubmitButton: {},
});

// ----------------------------------------------------------------------

const SignInForm: React.VFC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('forms.rules.email'))
      .required(t('forms.rules.required')),
    password: Yup.string().required(t('forms.rules.required')),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SignInSchema,
    onSubmit: async (values) => {
      if (error) {
        setError(undefined);
      }
      const resultAction = await dispatch(
        signIn({ email: values.email, password: values.password }),
      );
      if (signIn.rejected.match(resultAction)) {
        setError(resultAction.error.message);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form className={classes.signInForm} noValidate onSubmit={handleSubmit}>
        <Stack className={classes.signInFormContainer} spacing={3}>
          <TextField
            className={classes.signInEmail}
            fullWidth
            type="email"
            label={t('common.email')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            className={classes.signInPassword}
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label={t('common.password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack className={classes.signInActionsContainer}>
          <Link variant="subtitle2" component={RouterLink} to="/signUp">
            {t('pages.SignIn.createAccount')}
          </Link>
          <Link
            className={classes.signInActionsForgotPassword}
            component={RouterLink}
            variant="subtitle2"
            to="/lostPassword"
          >
            {t('pages.SignIn.lostPassword')}
          </Link>
        </Stack>

        <LoadingButton
          className={classes.signInSubmitButton}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {t('common.logIn')}
        </LoadingButton>
        {error && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: '#d32f2f',
            }}
          >
            {!!error &&
              t(`pages.SignIn.errors.${error}`, t('common.errorGeneric'))}
          </div>
        )}
      </Form>
    </FormikProvider>
  );
};

export default SignInForm;

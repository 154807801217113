import React, { Dispatch, SetStateAction } from 'react';
import { createUseStyles } from 'react-jss';
import { Drawer } from '@mui/material';
import { SiderList } from 'src/components/list/SiderList';

const useStyles = createUseStyles({
  sider: {
    height: 'auto',
    maxHeight: 'calc(100vh - 108px - 1vw - 2vw)',
    backgroundColor: '#f8f8f8',
    borderRadius: 30,
    boxShadow: '1px 1px 6px rgba(0,0,0,0.2)',
    padding: '1vw',
    marginRight: '3vw',
    marginLeft: '2vw',
    marginTop: '1vw',
    width: '21%',
    fontSize: '15px',
    overflowY: 'auto',
    alignItems: 'stretch',
    listStyle: 'none',

    '& span': {
      cursor: 'pointer',
    },
    '@media(max-width: 750px)': {
      display: 'none',
    },
  },
  siderMobile: {
    '@media(min-width: 751px)': {
      display: 'none',
    },
  },
  ul: {
    listStyleType: 'none',
    padding: '0px',
    margin: '0px',
  },
  li: {
    cursor: 'pointer',
    lineHeight: '1.7rem',
    '&:hover': {
      color: '#143779',
      textDecoration: 'underline',
    },
  },
  listElementContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  drawer: {
    '& h3': {
      fontWeight: 'bold',
      fontSize: '18px',
      color: '#143779',
    },
    backgroundColor: '#ececec',
  },
});

interface Props {
  currentFormItem: string;
  setCurrentForm: Dispatch<SetStateAction<string>>;
  openDrawer: boolean;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
}

export const EditProjectSider: React.VFC<Props> = ({
  currentFormItem,
  setCurrentForm,
  openDrawer,
  setOpenDrawer,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.sider}>
        <SiderList
          currentFormItem={currentFormItem}
          setCurrentForm={setCurrentForm}
          setOpenDrawer={setOpenDrawer}
          mobile={false}
        />
      </div>
      <div className={classes.siderMobile}>
        <Drawer
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          anchor="left"
          className={classes.drawer}
          sx={{
            '& .MuiPaper-root': {
              padding: '20px',
              backgroundColor: '#ececec',
            },
          }}
        >
          <SiderList
            currentFormItem={currentFormItem}
            setCurrentForm={setCurrentForm}
            setOpenDrawer={setOpenDrawer}
            mobile={true}
          />
        </Drawer>
      </div>
    </>
  );
};

import React from 'react';
import { createUseStyles } from 'react-jss';
import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const useStyles = createUseStyles({
  loggedLayoutContent: {
    // padding: '2vh 0vw 10vh',
  },
});

export const LoggedLayout: React.VFC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Box
        className={classes.loggedLayoutContent}
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        {children}
      </Box>
    </>
  );
};

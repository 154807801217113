import { useTranslation } from 'react-i18next';
import { Rule } from 'rc-field-form/lib/interface';

export const useFormRules = () => {
  const { t } = useTranslation();

  return {
    required: (options?: { whitespace?: boolean; type?: string }): Rule => ({
      required: true,
      message: t('forms.rules.required'),
      whitespace: options?.whitespace,
      type: options?.type as 'array',
    }),
    email: (): Rule => ({
      type: 'email',
      message: t('forms.rules.email'),
    }),
    url: (): Rule => ({
      type: 'url',
      message: t('forms.rules.url'),
    }),
    number: (): Rule => ({
      type: 'number',
    }),
    min: (value: number): Rule => ({
      min: value,
      message: t('forms.rules.min', { value }),
    }),
    max: (value: number): Rule => ({
      max: value,
      message: t('forms.rules.max', { value }),
    }),
  };
};

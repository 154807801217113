import React from 'react';

import { alpha } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { createUseStyles } from 'react-jss';
import { theme } from '../../constants/theme';

const background = {
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
};

const useStyles = createUseStyles({
  baseOptionChartStyle: {
    '&.apexcharts-canvas': {
      // Tooltip
      '.apexcharts-xaxistooltip': {
        ...background,
        border: 0,
        boxShadow: theme.customShadows.z24,
        color: theme.palette.text.primary,
        borderRadius: theme.shape.borderRadiusSm,
        '&:before': { borderBottomColor: 'transparent' },
        '&:after': {
          borderBottomColor: alpha(theme.palette.background.default, 0.72),
        },
      },
      '.apexcharts-tooltip.apexcharts-theme-light': {
        ...background,
        border: 0,
        boxShadow: theme.customShadows.z24,
        borderRadius: theme.shape.borderRadiusSm,
        '& .apexcharts-tooltip-title': {
          border: 0,
          textAlign: 'center',
          fontWeight: theme.typography.fontWeightBold,
          backgroundColor: theme.paletteExtended.grey[500_16],
          color:
            theme.palette.text[
              theme.palette.mode === 'light' ? 'secondary' : 'primary'
            ],
        },
      },
      // Legend
      '.apexcharts-legend': {
        padding: 0,
      },
      '.apexcharts-legend-series': {
        display: 'flex !important',
        alignItems: 'center',
      },
      '.apexcharts-legend-marker': {
        marginRight: 8,
      },
      '.apexcharts-legend-text': {
        lineHeight: '18px',
        textTransform: 'capitalize',
      },
    },
  },
});

// ----------------------------------------------------------------------

export const BaseOptionChartStyle = () => {
  const classes = useStyles();

  return <GlobalStyles styles={classes.baseOptionChartStyle} />;
};

const BaseOptionChart = () => {
  const LABEL_TOTAL = {
    show: true,
    label: 'Total',
    color: theme.palette.text.secondary,
    ...theme.typography.subtitle2,
    fontSize: `${theme.typography.subtitle2.fontSize}`,
  };

  const LABEL_VALUE = {
    offsetY: 8,
    color: theme.palette.text.primary,
    ...theme.typography.h3,
    fontSize: `${theme.typography.h3.fontSize}`,
  };

  const colorsApex: any[] = [
    theme.paletteExtended.primary.main,
    theme.paletteExtended.chart.yellow[0],
    theme.paletteExtended.chart.blue[0],
    theme.paletteExtended.chart.violet[0],
    theme.paletteExtended.chart.green[0],
    theme.paletteExtended.chart.red[0],
  ];

  const chartApex: ApexChart = {
    toolbar: { show: false },
    zoom: { enabled: false },
    // animations: { enabled: false },
    foreColor: theme.palette.text.disabled,
    fontFamily: theme.typography.fontFamily,
  };

  const statesApex: ApexStates = {
    hover: {
      filter: {
        type: 'lighten',
        value: 0.04,
      },
    },
    active: {
      filter: {
        type: 'darken',
        value: 0.88,
      },
    },
  };

  const fillApex: ApexFill = {
    opacity: 1,
    gradient: {
      type: 'vertical',
      shadeIntensity: 0,
      opacityFrom: 0.4,
      opacityTo: 0,
      stops: [0, 100],
    },
  };

  const dataLabelsApex: ApexDataLabels = { enabled: false };

  const strokeApex: ApexStroke = {
    width: 3,
    curve: 'smooth',
    lineCap: 'round',
  };

  const gridApex: ApexGrid = {
    strokeDashArray: 3,
    borderColor: theme.palette.divider,
  };

  const xAxisApex: ApexXAxis = {
    axisBorder: { show: false },
    axisTicks: { show: false },
  };

  const markersApex: ApexMarkers = {
    size: 0,
    strokeColors: theme.palette.background.paper,
  };

  const tooltipApex: ApexTooltip = {
    x: {
      show: false,
    },
  };

  const legendApex: ApexLegend = {
    show: true,
    fontSize: '13',
    position: 'top',
    horizontalAlign: 'right',
    markers: {
      radius: 12,
    },
    fontWeight: 500,
    itemMargin: { horizontal: 12 },
    labels: {
      colors: theme.palette.text.primary,
    },
  };

  const plotOptionsApex: ApexPlotOptions = {
    // Bar
    bar: {
      columnWidth: '28%',
      borderRadius: 4,
    },
    // Pie + Donut
    pie: {
      donut: {
        labels: {
          show: true,
          value: LABEL_VALUE,
          total: LABEL_TOTAL,
        },
      },
    },
    // Radialbar
    radialBar: {
      track: {
        strokeWidth: '100%',
        background: theme.paletteExtended.grey[500_16],
      },
      dataLabels: {
        value: LABEL_VALUE,
        total: LABEL_TOTAL,
      },
    },
    // Radar
    radar: {
      polygons: {
        fill: { colors: ['transparent'] },
        strokeColors: theme.palette.divider,
        connectorColors: theme.palette.divider,
      },
    },
    // polarArea
    polarArea: {
      rings: {
        strokeColor: theme.palette.divider,
      },
      spokes: {
        connectorColors: theme.palette.divider,
      },
    },
  };

  const responsiveApex: ApexResponsive[] = [
    {
      // sm
      breakpoint: theme.breakpoints.values.sm,
      options: {
        plotOptions: { bar: { columnWidth: '40%' } },
      },
    },
    {
      // md
      breakpoint: theme.breakpoints.values.md,
      options: {
        plotOptions: { bar: { columnWidth: '32%' } },
      },
    },
  ];

  const apexOptions: ApexOptions = {
    // Colors
    colors: colorsApex,

    // Chart
    chart: chartApex,

    // States
    states: statesApex,

    // Fill
    fill: fillApex,

    // Datalabels
    dataLabels: dataLabelsApex,

    // Stroke
    stroke: strokeApex,

    // Grid
    grid: gridApex,

    // Xaxis
    xaxis: xAxisApex,

    // Markers
    markers: markersApex,

    // Tooltip
    tooltip: tooltipApex,

    // Legend
    legend: legendApex,

    // plotOptions
    plotOptions: plotOptionsApex,

    // Responsive
    responsive: responsiveApex,
  };

  return apexOptions;
};

export default BaseOptionChart;

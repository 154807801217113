import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  deleteProject,
  getAll,
  Project,
  updateProjectNameAndSlug,
} from 'src/slices/project';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ProjectSlug } from './ProjectSlug';
import { LoadingButton } from '@mui/lab';

interface Props {
  project: Project;
}
export const CardDropDown: React.VFC<Props> = ({ project }) => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [confirmProjectToDelete, setConfirmProjectToDelete] =
    React.useState<string>('');
  const { isSlugValid } = useAppSelector(({ project: { isSlugValid } }) => ({
    isSlugValid,
  }));

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteOk = async () => {
    setOpenDelete(false);
    await dispatch(deleteProject(confirmProjectToDelete));
    await dispatch(getAll());
  };

  const SignInSchema = Yup.object().shape({
    name: Yup.string().required('Le nom est obligatoire'),
    slug: Yup.string().required('L&aposurl est obligatoire'),
  });

  const formik = useFormik({
    initialValues: {
      name: project.name,
      slug: project.slug,
    },
    validationSchema: SignInSchema,
    onSubmit: async () => {
      setOpenUpdate(false);
      const result = await dispatch(
        updateProjectNameAndSlug({
          newName: formik.values.name,
          newSlug: formik.values.slug,
          currentSlug: project.slug,
        }),
      );
      if (result.meta.requestStatus === 'rejected') console.log('error');
      else console.log('success');
      await dispatch(getAll());
    },
  });

  const { isSubmitting, handleSubmit } = formik;

  return (
    <>
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>Supprimer le projet</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={3}>
            <DialogContentText>
              Cette action est irréversible et va supprimer le projet
              définitevement. <br />
              <span>{`\nTapez "${project.slug}" pour confirmer.`}</span>
            </DialogContentText>
            <TextField
              onChange={(e) => setConfirmProjectToDelete(e.currentTarget.value)}
            />
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button onClick={() => setOpenDelete(false)}>Annuler</Button>
            <Button
              variant="contained"
              disabled={confirmProjectToDelete !== project.slug}
              onClick={handleDeleteOk}
            >
              Supprimer
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openUpdate}
        onClose={() => setOpenUpdate(false)}
        sx={{
          '& .MuiDialogContent-root': {
            paddingTop: '30px',
          },
          '& .MuiDialogContentText-root': {
            marginTop: '20px',
            marginBottom: '30px',
          },
        }}
      >
        <DialogTitle>Modifier le projet</DialogTitle>
        <Divider />
        <ProjectSlug formik={formik} slugToIgnore={project.slug} />
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <Button onClick={() => setOpenUpdate(false)}>Annuler</Button>
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
              disabled={!isSlugValid}
            >
              Valider
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
      <IconButton aria-label="settings" onClick={handleMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu-cards"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ p: 3 }}
      >
        {project.published && (
          <MenuItem>
            <a
              href={`https://sites.laserpitch.com/${project.slug}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              Voir le site
            </a>
          </MenuItem>
        )}
        <MenuItem onClick={() => setOpenUpdate(true)}>
          Modifier le nom ou l&apos;url
        </MenuItem>
        <MenuItem onClick={() => setOpenDelete(true)}>Supprimer</MenuItem>
      </Menu>
    </>
  );
};

import React from 'react';

import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { theme } from '../../constants/theme';

interface StyleProps {
  paletteCategory: {
    lighter: string;
    light: string;
    main: string;
    dark: string;
    darker: string;
    contrastText: string;
  };
}

const StatCardContainer = styled(Card)<StyleProps>(({ paletteCategory }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: paletteCategory.darker,
  backgroundColor: paletteCategory.lighter,
}));

const StatCardIconWrapper = styled('div')<StyleProps>(
  ({ paletteCategory }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: paletteCategory.dark,
    backgroundImage: `linear-gradient(135deg, ${alpha(
      paletteCategory.dark,
      0,
    )} 0%, ${alpha(paletteCategory.dark, 0.24)} 100%)`,
  }),
);

const useStyles = createUseStyles({
  statCardContainer: {},
  statCardIconWrapper: {},
  statCardNumber: {},
  statCardTitle: {
    opacity: 0.72,
  },
});

interface Props {
  total: string;
  title: string;
  icon: React.ReactElement;
  paletteCategory: {
    lighter: string;
    light: string;
    main: string;
    dark: string;
    darker: string;
    contrastText: string;
  };
}

const StatCard: React.VFC<Props> = ({
  total,
  title,
  icon,
  paletteCategory,
}) => {
  const classes = useStyles();

  return (
    <StatCardContainer
      className={classes.statCardContainer}
      paletteCategory={paletteCategory}
    >
      <StatCardIconWrapper
        className={classes.statCardIconWrapper}
        paletteCategory={paletteCategory}
      >
        {icon}
      </StatCardIconWrapper>
      <Typography className={classes.statCardNumber} variant="h3">
        {total}
      </Typography>
      <Typography className={classes.statCardTitle} variant="subtitle2">
        {title}
      </Typography>
    </StatCardContainer>
  );
};

export default StatCard;

import React, { Dispatch, SetStateAction } from 'react';
import { createUseStyles } from 'react-jss';
import { Divider, List, Typography } from '@mui/material';
import ListItemCustom from 'src/components/list/ListItemCustom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import HorizontalSplitOutlinedIcon from '@mui/icons-material/HorizontalSplitOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Google } from '@mui/icons-material';

const useStyles = createUseStyles({
  sider: {
    height: 'calc(100vh - 300px)',
    backgroundColor: '#f8f8f8',
    borderRadius: 30,
    boxShadow: '1px 1px 6px rgba(0,0,0,0.2)',
    padding: 35,
    marginRight: 100,
    marginLeft: 55,
    marginTop: 35,
    width: '21%',
    fontSize: '15px',
    overflowY: 'auto',
    alignItems: 'stretch',
    listStyle: 'none',
    '& h3': {
      fontWeight: 'bold',
      fontSize: '18px',
      marginTop: 20,
      color: '#143779',
      marginBottom: '0.3em',
      '&:first-of-type': {
        marginTop: 0,
      },
    },
    '& span': {
      cursor: 'pointer',
    },
    '@media(max-width: 750px)': {
      display: 'none',
    },
  },
  siderMobile: {
    '@media(min-width: 751px)': {
      display: 'none',
    },
  },
  title: {
    fontSize: '18px',
    lineHeight: '30px',
    padding: '1vw',
    fontWeight: 'bolder',
  },
  ul: {
    listStyleType: 'none',
    padding: '0px',
    margin: '0px',
  },
  li: {
    cursor: 'pointer',
    lineHeight: '1.7rem',
    '&:hover': {
      color: '#143779',
      textDecoration: 'underline',
    },
  },
  listElementContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  drawer: {
    '& h3': {
      fontWeight: 'bold',
      fontSize: '18px',
      color: '#143779',
    },
    backgroundColor: '#ececec',
  },
});

interface Props {
  currentFormItem: string;
  setCurrentForm: Dispatch<SetStateAction<string>>;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
  mobile: boolean;
}

export const SiderList: React.VFC<Props> = ({
  currentFormItem,
  setCurrentForm,
  setOpenDrawer,
  mobile,
}) => {
  const classes = useStyles();

  return (
    <>
      <List
        subheader={
          <Typography className={classes.title}>
            Sections obligatoires
          </Typography>
        }
      >
        <ListItemCustom
          title="Écran d'accueil"
          onClick={() => {
            setCurrentForm('home');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'home'}
          icon={<HomeOutlinedIcon />}
        />
        <ListItemCustom
          title="Solution"
          onClick={() => {
            setCurrentForm('solution');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'solution'}
          icon={<EmojiObjectsOutlinedIcon />}
        />
        <ListItemCustom
          title="Bénéfices clients"
          onClick={() => {
            setCurrentForm('benefits');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'benefits'}
          icon={<AutoFixHighOutlinedIcon />}
        />
        <ListItemCustom
          title="Contact"
          onClick={() => {
            setCurrentForm('contact');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'contact'}
          icon={<CallOutlinedIcon />}
        />
      </List>
      <Divider sx={{ m: 2 }} />
      <List
        subheader={
          <Typography className={classes.title}>
            Sections complémentaires
          </Typography>
        }
      >
        <ListItemCustom
          title="Authentification"
          onClick={() => {
            setCurrentForm('login');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'login'}
          icon={<LockOpenOutlinedIcon />}
        />
        <ListItemCustom
          title="Offre Spéciale"
          onClick={() => {
            setCurrentForm('specialoffer');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'specialoffer'}
          icon={<LocalOfferOutlinedIcon />}
        />
        <ListItemCustom
          title="Références"
          onClick={() => {
            setCurrentForm('references');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'references'}
          icon={<AddBusinessOutlinedIcon />}
        />
        <ListItemCustom
          title="Équipe"
          onClick={() => {
            setCurrentForm('team');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'team'}
          icon={<PeopleAltOutlinedIcon />}
        />
        <ListItemCustom
          title="Témoignages clients"
          onClick={() => {
            setCurrentForm('testimonials');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'testimonials'}
          icon={<InsertCommentOutlinedIcon />}
        />
        <ListItemCustom
          title="FAQ"
          onClick={() => {
            setCurrentForm('faq');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'faq'}
          icon={<HelpOutlineOutlinedIcon />}
        />
        <ListItemCustom
          title="Tarifs"
          onClick={() => {
            setCurrentForm('pricing');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'pricing'}
          icon={<PaymentOutlinedIcon />}
        />
        <ListItemCustom
          title="Pied de page"
          onClick={() => {
            setCurrentForm('footer');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'footer'}
          icon={<HorizontalSplitOutlinedIcon />}
        />
      </List>
      <Divider sx={{ m: 2 }} />
      <List
        subheader={
          <Typography className={classes.title}>
            Pages supplémentaires
          </Typography>
        }
      >
        <ListItemCustom
          title="Blog"
          onClick={() => {
            setCurrentForm('pages');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'pages'}
          icon={<AddBoxIcon />}
        />
      </List>
      <Divider sx={{ m: 2 }} />
      <List
        subheader={
          <Typography className={classes.title}>
            Options de présentation
          </Typography>
        }
      >
        <ListItemCustom
          title="Couleurs"
          onClick={() => {
            setCurrentForm('colorpicker');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'colorpicker'}
          icon={<PaletteOutlinedIcon />}
        />
      </List>
      <Divider sx={{ m: 2 }} />
      <List
        subheader={
          <Typography className={classes.title}>
            Options de publication
          </Typography>
        }
      >
        <ListItemCustom
          title="Nom de domaine personnalisé"
          onClick={() => {
            setCurrentForm('customDomain');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'customDomain'}
          icon={<LanguageOutlinedIcon />}
        />
        <ListItemCustom
          title="Google Analytics"
          onClick={() => {
            setCurrentForm('googleAnalytics');
            mobile && setOpenDrawer(false);
          }}
          selected={currentFormItem === 'googleAnalytics'}
          icon={<Google />}
        />
      </List>
    </>
  );
};
